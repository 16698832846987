import React,{ useContext } from 'react';
import './FingerprintButton.css';
import FingerprintCapture from './components/FigerprintCapture/FingerprintCapture';
import AppContext from '../../../../../../commons/components/AppContext/AppContext';


const FingerprintButton = ({_onClick}) => {    
    const globalContext = useContext(AppContext);
    const activeClass = `contenedorbtnautentica`;

    const activeButton = () => {
        if (globalContext.validateDNI) {
            globalContext.handleClickFinger();
        };
    };

    return (
        <>
            <div className={`FaceContainer animated animatedFadeInUp fadeInUp`} onClick={activeButton}>
                <div className={activeClass}>
                    <div className='btnFingerprint'></div>
                    <span className="btnselectautentica">Ingrese su Huella Dactilar</span>
                </div>
            </div>
            <FingerprintCapture _onClick={_onClick}></FingerprintCapture>
        </>
    );
};

export default FingerprintButton;

