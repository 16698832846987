import React, {useState,useContext,useEffect} from 'react';
import './SearchDeathOptions.css';
import { Container } from 'react-bootstrap';
import ImpresionPdf from './components/BtnContinuarDeath/BtnContinuarDeath';
import InputValida from './components/InputDeath/InputDeath';
import Wainting from '../../../../commons/components/Waiting/Waiting';
import ErrorMessage from '../../../../commons/components/ErrorMessage/ErrorMessage';
import imageiconurl from "../../../../assets/images/iconalert.png";
import AppContext from "../../../../commons/components/AppContext/AppContext";
import { storeBitacora } from "../../../../Services/services";

const SearchDeathOptions = () => {
    const [showWait, setShowWait] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const globalContext = useContext(AppContext);
    const id_bitacora = globalContext.id_bitacora;
    const dni_user = globalContext.dni;
    const [dni, setDni] = useState(null);
    
    const sendBitacora = async (dni, id_bitacora) => {
        const bitacora = {
            searchDeath: {
                status: true,
                data: "Buscar certificado de defuncion",
                timestamp: new Date().toISOString(),
                dni: dni,
            }
        };
        await storeBitacora(JSON.stringify(bitacora), id_bitacora , true ,dni);
    };

    useEffect(() => {
        console.log('dni', dni_user);
        if(dni_user){
            sendBitacora(dni_user, id_bitacora);
        };
    }, [dni_user]);

    const onAccept = () =>{
        setErrorMessage(false);
    };

    return (
        <> 
           <div className="center-screen">
                <Container>
                        <InputValida  _setDni={setDni}></InputValida>
                        <ImpresionPdf setErrorMessage={setErrorMessage} setShowWait={setShowWait} dni={dni}></ImpresionPdf>
                </Container>
                <Wainting show={showWait} ></Wainting>
                <ErrorMessage show={errorMessage} message="No hay registro de defunción, valide los datos ingresados." onAccept={onAccept} imageicon={imageiconurl}></ErrorMessage>
            </div>
        </>
    );
};

export default SearchDeathOptions;