import React, { useState, useEffect, useContext, useRef } from 'react';
import "./Cierre.css";
import { useHistory } from "react-router-dom";
import alertIcon from "../../assets/images/iconalert.png";
import ErrorMessage from "../../commons/components/ErrorMessage/ErrorMessage";
import AppContext from "../../commons/components/AppContext/AppContext";

const Cierre = () => {
    const history = useHistory();
    const globalContext = useContext(AppContext);
    const [showErrorMessage, setShowErrorMessage] = useState(true);
    const [timerExpired, setTimerExpired] = useState(false);
    const [delayedRedirect, setDelayedRedirect] = useState(false);
    const timerRef = useRef(null);

    const handleAcceptError = () => {
        setShowErrorMessage(false);
    };

    useEffect(() => {
        if (!globalContext.nacimiento) {
            timerRef.current = setTimeout(() => {
                setTimerExpired(true);
                setShowErrorMessage(false);
            }, 12000);
        } else {
            setTimerExpired(true);
            setShowErrorMessage(false);
        }

        return () => {
            clearTimeout(timerRef.current);
        };
    }, [globalContext.nacimiento]);

    const handleHelpButtonClick = () => {
        clearTimeout(timerRef.current);
        setShowErrorMessage(true);
    };

    useEffect(() => {
        if (!showErrorMessage || timerExpired) {
            const redirectTimer = setTimeout(() => {
                setDelayedRedirect(true);
            }, 5000);

            return () => clearTimeout(redirectTimer);
        }
    }, [showErrorMessage, timerExpired]);

    useEffect(() => {
        if (delayedRedirect) {
            history.push(process.env.REACT_APP_BASE_URL + "Welcome");
        }
    }, [delayedRedirect, history]);

    return (
        <div>
            <div className="contenedorcierre">
                <img className='imagecierre' src={require('../../assets/images/logotipoCierre.png')} alt="React Logo" />                    
            </div>
            {!globalContext.nacimiento && (
                <ErrorMessage imageicon={alertIcon} show={showErrorMessage} message={`Necesitas ayuda con algo adicional.?`} onAccept={handleAcceptError} nobutton={true} onHelp={handleHelpButtonClick}></ErrorMessage>
            )}
        </div>
    );
};
 
export default Cierre;
    
    