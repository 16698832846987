import React from 'react';
import './Waiting.css';
import CargadorAnimado from '../../components/CargadorAnimado/CargadorAnimado';

const Wainting = ({ show }) => {
   
    if (!show) {
        return null;
    };

    return (
        <>
            <div className='waiting-overlay'>
                <div className='waiting-content'>
                    <div className='loading-text'>Por favor espera, estamos</div>
                    <div className='loading-text'>procesando tus datos.</div>
                    <CargadorAnimado />
                </div>
            </div>
        </>
    );
};

export default Wainting;