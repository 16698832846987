import React from "react";
import "./DeathCertificate.css";
import Image from "../../../../../../assets/images/certdefuncion.png";
import { useHistory } from "react-router-dom";

const DeathCertificate = () => {
     const history = useHistory();

     const handleClick = async () => {
          history.push(process.env.REACT_APP_BASE_URL + "searchDeath");
     };

     return (
          <>
               <div className="CertificateContainer animated animatedFadeInUp fadeInUp dos">
                    <div onClick={handleClick}>
                         <img src={Image} className="certbtnimage" />
                         <p className="textbtnselector">Certificado de <span>Defunción</span></p>
                    </div>
               </div>
          </>
     );
};

export default DeathCertificate;
