import React from 'react';
import ImageNacimiento from '../../../../assets/images/repodni.png';
import './PreviewDNIRepo.css';
import GlobalTitleCertificate from '../../../../commons/components/TitulosCertificados/GlobalTitleCertificateVertical/GlobalTitleCertificateVertical';
import PreviewDNIRepoOptions from './PreviewDNIRepoOptions/PreviewDNIRepoOptions';
import LogotipoRnp from '../../../../commons/components/Logotipo/LogotipoRnp/LogotipoRnp';


const PreviewDNIRepo = () => {  

    return (
        <div className='contenedortitle'>
            <div>
                <div>
                    <LogotipoRnp></LogotipoRnp>
                    <GlobalTitleCertificate  imgcertificado={ImageNacimiento} nameligth="Reposición de" namebold="" nameorange="DNI" ></GlobalTitleCertificate>
                </div>
                <PreviewDNIRepoOptions></PreviewDNIRepoOptions>
            </div>
        </div>
    );
};

export default PreviewDNIRepo;
