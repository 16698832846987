import React from 'react';
import Button from 'react-bootstrap/Button';
import './BasicButton.css';

const BasicButton = ({ text, size,_className, _onClick,_enabled}) => {
    const isDisabled = _enabled === undefined ? false : !_enabled;

    return (
        <>
            <Button onClick={_onClick} className={_className} size={size} disabled={isDisabled}>{text}</Button>
        </>
    )
};

export default BasicButton;