import React from 'react';
import './GeneralPayOptiondBtnPdf.css';

const GeneralPayOptiondBtnPdf = ({onClickFun}) => {

    const handlePay = (event) =>{
        onClickFun();
    };

    return (
        <>
            <div className='contenedorcertificado3 animated animatedFadeInUp fadeInUp'>
                <a onClick={handlePay}>
                    <p><img className="icondescarga" src={require('../../../../../../../assets/images/paytarjet.png')} alt="Impresion" /> Pagar</p>                          
                </a>  
            </div>
        </>
    );
};

export default GeneralPayOptiondBtnPdf;