import React from 'react';
import './StartActive.css';
import BasicButton from '../../../../commons/components/Buttons/BasicButton/BasicButton';

const StartActive = ({ _className,text, size , _handleClick}) => {
   
    return (
        <>            
            <BasicButton  _onClick={_handleClick} _className={_className} text={text} size={size}></BasicButton>
        </>
    );
};

export default StartActive;