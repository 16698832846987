import React, { useEffect } from 'react';
import "./Alerta.css";
import { useHistory } from "react-router-dom";

const Alerta = () => {
    const alertMessage="Se sobrepaso el tiempo limite de espera, punto temporalmente deshabilitado.";
    const history = useHistory();

    useEffect(() => {
        const timer = setTimeout(() => {
            history.push(process.env.REACT_APP_BASE_URL + "Welcome");
        }, 30000);
        return () => {
            clearTimeout(timer);
        };
    }, []);


    return (
        <div className="alerta">
            <div className="contenedorcierre">
                <img className='alertlogotipo' src={require('../../assets/images/rnplogotipowhite.png')} alt="React Logo" />  
                <div className='alertcontent'>
                    <img className='iconoalertrojo' src={require('../../assets/images/iconerroralert.png')} alt="React Logo" />
                    <h3>No hay comunicación </h3>
                    <p>{alertMessage}</p>
                </div>                                  
            </div>
        </div>
    );
};
 
export default Alerta;
