import React, {useContext} from 'react';
import './NameUser.css';
import AppContext from '../../AppContext/AppContext';



const NameUser = ({ type }) => {

    const globalContext = useContext(AppContext);

    return (
        <>
            <div className='animated animatedFadeInUp fadeInUp'>
            {type === 'Selector' ? (
                <h4 className="usernameid"><span>Bienvenido (a) </span>{[globalContext.person?.Nombres, globalContext.person?.PrimerApellido, globalContext.person?.SegundoApellido].filter(Boolean).join(" ")}</h4>
            ) : (
                <h4 className="usernameid"><span>Usuario (a): </span>{[globalContext.person?.Nombres, globalContext.person?.PrimerApellido, globalContext.person?.SegundoApellido].filter(Boolean).join(" ")}</h4>
            )}
            </div>           
        </>
    );
};

export default NameUser;