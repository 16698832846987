import React from 'react';
import './GlobalTitleCertificateVertical.css';

const GlobalTitleCertificateVertical = ({ imgcertificado, nameligth, namebold, nameorange}) => {
    
    return (
        <>     
            <div className='animated animatedFadeInUp fadeInUp'>
                <div>
                    <img src={imgcertificado} className='certbtnimage1'/>
                    <p className="textbtnselector tamañoletra">{nameligth} <br />{namebold ? <strong>{namebold}<br /></strong>  : null}  <span>{nameorange}</span></p>
                </div>               
            </div>
        </>
    );
};

export default GlobalTitleCertificateVertical;