import React from 'react';
import GlobalTitleCertificate from '../../commons/components/TitulosCertificados/GlobalTitleCertificateVertical/GlobalTitleCertificateVertical';
import ImageNacimiento from '../../assets/images/certnacimiento.png';
import './PreviewCertificate.css';
import DocumentsOptions from './components/DocumentsOptions/PreviewCertOptions';
import LogotipoRnp from '../../commons/components/Logotipo/LogotipoRnp/LogotipoRnp';

const PreviewCertificate = () => {  

    return (
        <div className='contenedortitle'>
            <div>
                <div>
                    <LogotipoRnp></LogotipoRnp>
                    <GlobalTitleCertificate imgcertificado={ImageNacimiento} nameligth="Certificado de" namebold="" nameorange="Nacimiento" ></GlobalTitleCertificate>
                </div>
                <DocumentsOptions></DocumentsOptions>
            </div>
        </div>
    );
};

export default PreviewCertificate;
