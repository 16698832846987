import axios from "axios";
import { storeBitacora} from './services'

export const invokeGetPayDocument = async (obj, data_bitacora) => {
    console.log("======================== invokeGetPayDocument  ↓ ");
    const baseUrl = process.env.REACT_APP_API_URL;
    const apiEndpoint = `${baseUrl}/${process.env.REACT_APP_CERT_PAY_DOCUMENT}`;
    const storedToken = localStorage.getItem("tk"); 
    console.log("apiEndpoint", apiEndpoint);
    console.log("OBJ", obj);
    console.log("storedToken", storedToken); 
    const requestBody = { ...obj }; 
    try {
        const response = await axios.post(apiEndpoint, requestBody, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storedToken}`
            }
        });
        const responseBody = typeof response.data.body === 'string' ? JSON.parse(response.data.body) : response.data.body;
        const bitacoraGetPayDocument = {
            getPayDocument: {
                status: responseBody?.status,
                data: responseBody,
                timestamp: new Date().toISOString(),
                dni: data_bitacora.dni,
            }
        };
        await storeBitacora(JSON.stringify(bitacoraGetPayDocument), data_bitacora.id_bitacora, true, data_bitacora.dni); 
        console.log("responseInvoke", responseBody);
        console.log("======================== invokeGetPayDocument  ↑  ");
        return responseBody;
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Error desconocido';
        const bitacoraGetPayDocument = {
            getPayDocument: {
                status: false,
                data: errorMessage,
                timestamp: new Date().toISOString(),
                dni: data_bitacora.dni,
            }
        };
        await storeBitacora(JSON.stringify(bitacoraGetPayDocument), data_bitacora.id_bitacora, true, data_bitacora.dni);
        console.error("Error invoking get pay document:", error);
        throw error;
    }
};

export const invokePayTGR = async (obj, data_bitacora) => {
    console.log("======================== invokePayTGR  ↓ ");
    const baseUrl = process.env.REACT_APP_API_URL;
    const apiEndpoint = `${baseUrl}/${process.env.REACT_APP_CERT_PAY_TGR}`;
    const storedToken = localStorage.getItem("tk"); 
    console.log("apiEndpoint", apiEndpoint);
    console.log("OBJ", obj);
    console.log("storedToken", storedToken);
    const requestBody = { ...obj };
    try {
        const response = await axios.post(apiEndpoint, requestBody, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storedToken}`
            }
        });
        const responseBody = typeof response.data.body === 'string' ? JSON.parse(response.data.body) : response.data.body;
        const bitacoraPayTGR = {
            payTGR: {
                status: responseBody?.status,
                data: responseBody,
                timestamp: new Date().toISOString(),
                dni: data_bitacora.dni,
            }
        };
        await storeBitacora(JSON.stringify(bitacoraPayTGR), data_bitacora.id_bitacora, true, data_bitacora.dni); 
        console.log("responseInvoke", responseBody);
        console.log("======================== invokePayTGR  ↑  ");
        return responseBody;
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Error desconocido';
        const bitacoraPayTGR = {
            payTGR: {
                status: false,
                data: errorMessage,
                timestamp: new Date().toISOString(),
                dni: data_bitacora.dni,
            }
        };
        await storeBitacora(JSON.stringify(bitacoraPayTGR), data_bitacora.id_bitacora, true, data_bitacora.dni);
        console.error("Error invoking pay TGR:", error);
        throw error;
    };
};

export const invokeValidateTGR = async (obj, data_bitacora) => {
    console.log("======================== invokeValidateTGR  ↓ ");
    const baseUrl = process.env.REACT_APP_API_URL;
    const apiEndpoint = `${baseUrl}/${process.env.REACT_APP_CERT_VALIDATE_TGR}`;
    const storedToken = localStorage.getItem("tk");
    console.log("apiEndpoint", apiEndpoint);
    console.log("OBJ", obj);
    console.log("storedToken", storedToken);
    const requestBody = { ...obj };
    try {
        const response = await axios.post(apiEndpoint, requestBody, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storedToken}`
            }
        });
        const responseBody = typeof response.data.body === 'string' ? JSON.parse(response.data.body) : response.data.body;
        const bitacoraValidateTGR = {
            validateTGR: {
                status: responseBody?.status,
                data: responseBody,
                timestamp: new Date().toISOString(),
                dni: data_bitacora.dni,
            }
        };
        await storeBitacora(JSON.stringify(bitacoraValidateTGR), data_bitacora.id_bitacora, true, data_bitacora.dni);
        console.log("responseInvoke", responseBody);
        console.log("======================== invokeValidateTGR  ↑  ");
        return responseBody;
    } catch (error) {
        const errorMessage = error.response?.data?.message || error.message || 'Error desconocido';
        const bitacoraValidateTGR = {
            validateTGR: {
                status: false,
                data: errorMessage,
                timestamp: new Date().toISOString(),
                dni: data_bitacora.dni,
            }
        };
        await storeBitacora(JSON.stringify(bitacoraValidateTGR), data_bitacora.id_bitacora, true, data_bitacora.dni);
        console.error("Error invoking validate TGR:", error);
        throw error;
    };
};

export const invokeCreateTGR = async (obj) => {
    console.log("======================== invokeCreateTGR  ↓ ");
    const baseUrl = process.env.REACT_APP_API_URL;
    const apiEndpoint = `${baseUrl}/${process.env.REACT_APP_CERT_CREATE_TGR}`;
    const storedToken = localStorage.getItem("tk");
    console.log("apiEndpoint", apiEndpoint);
    console.log("OBJ", obj);
    console.log("storedToken", storedToken);
    const requestBody = { ...obj };
    try {
        const response = await axios.post(apiEndpoint, requestBody, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storedToken}`
            }
        });
        console.log("responseInvoke", response.data);
        console.log("======================== invokeCreateTGR  ↑  ");
        return response.data;
    } catch (error) {
        console.error("Error invoking create TGR:", error);
        throw error;
    };
};

export const invokeCBirth = async (dni, dni_solicitante) => {    
    console.log("======================== invokeCBirth  ↓ ");
    const baseUrl = process.env.REACT_APP_API_URL;
    const apiEndpoint = `${baseUrl}/${process.env.REACT_APP_CERT_BIRTH}`;
    const storedToken = localStorage.getItem("tk");
    console.log("apiEndpoint", apiEndpoint);
    console.log("dni", dni);
    console.log("dni_solicitante", dni_solicitante);
    console.log("storedToken", storedToken);
    const requestBody = {
        dni: dni,
        dni_solicitante: dni_solicitante
    };
    try {
        const response = await axios.post(apiEndpoint, requestBody, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storedToken}`
            }
        });
        const responseBody = typeof response.data.body === 'string' ? JSON.parse(response.data.body) : response.data.body;
        console.log("responseInvoke", responseBody);
        console.log("======================== invokeCBirth  ↑  ");
        return responseBody;
    } catch (error) {
        console.error("Error invoking C Birth:", error);
        throw error;
    }
};