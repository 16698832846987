import React from 'react';
import './BirthCertificate.css';
import Image from '../../../../../../assets/images/certnacimiento.png';
import { useHistory } from "react-router-dom";

const BirthCertificate = ({setShowErrorMessage,setShowWaiting}) => {
    const history = useHistory();

    const handleClick = async() =>{
        history.push(process.env.REACT_APP_BASE_URL + 'certificateSelector');
    };

    return (
        <>
            <div className='CertificateContainer animated animatedFadeInUp fadeInUp'>
                <div onClick={handleClick}>
                    <img src={Image} className='certbtnimage'/>
                    <p className="textbtnselector">Certificado de <span>Nacimiento</span></p>
                </div>
            </div>
        </>
    );
};

export default BirthCertificate;