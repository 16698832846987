import React, { useEffect, useState, useRef, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import './FacialCapture.css';
import Image from '../../../../../../../../assets/images/rnplogotipowhite.png';
import AppContext from '../../../../../../../../commons/components/AppContext/AppContext';
import WarningMessage from '../../../../../../../../commons/components/WarningMessage/WarningMessage';
import imageiconurl from "../../../../../../../../assets/images/iconalert.png";

const FacialCapture = ({ _onClick }) => {
    const globalContext = useContext(AppContext);
    const { _setFacial, _setFinger, showFacialCapture: _show, hideFacialCapture: _hide } = globalContext;
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const videoRef = useRef(null);
    const [selectedCamera, setSelectedCamera] = useState('');
    const [capturedImage, setCapturedImage] = useState(null);
    const [captureMode, setCaptureMode] = useState(false);

    useEffect(() => {
        const storedCamera = localStorage.getItem('selectedCamera');
        if (storedCamera) {
            setSelectedCamera(storedCamera);
        };
    }, []);

    useEffect(() => {
        _setFacial(capturedImage);
        console.log("completa facereconigtion", capturedImage);
        _setFinger(null);
    }, [capturedImage]);

    const handleShow = async () => {
        try {
            console.log(_show);
            setShowErrorMessage(true);
            const stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: selectedCamera } });
            videoRef.current.srcObject = stream;
            setCaptureMode(false);
        } catch (error) {
            console.error('Error al acceder a la cámara:', error);
        };
    };

    const onAccept = () => {
        setShowErrorMessage(false);
    };

    const handleHide = () => {
        if (videoRef.current.srcObject) {
            const stream = videoRef.current.srcObject;
            const tracks = stream.getTracks();
            tracks.forEach(track => track.stop());
            videoRef.current.srcObject = null;
        };
        _hide();
        _onClick();
    };

    const handleCapture = () => {
        if (!captureMode) {
            const canvas = document.createElement('canvas');
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            const context = canvas.getContext('2d');
            context.drawImage(
                videoRef.current,
                0,
                0,
                canvas.width,
                canvas.height
            );
            let capturedDataURL = canvas.toDataURL('image/png');
            videoRef.current.srcObject = null;
            videoRef.current.style.display = 'none';
            const capturedImage = document.createElement('img');
            capturedImage.src = capturedDataURL;
            capturedImage.style.width = '523';
            capturedImage.style.height = '528';
            capturedImage.className = 'videoMirror';
            videoRef.current.parentElement.appendChild(capturedImage);
            capturedDataURL = capturedDataURL.replace("data:image/png;base64,", "");
            setCapturedImage(capturedDataURL);
        } else {
            videoRef.current.style.display = 'block';
            const capturedImage = videoRef.current.parentElement.querySelector('img');
            capturedImage.remove();
            if (videoRef.current.srcObject) {
                const stream = videoRef.current.srcObject;
                const tracks = stream.getTracks();
                tracks.forEach((track) => track.stop());
            };
            navigator.mediaDevices.getUserMedia({ video: { deviceId: selectedCamera } }).then((stream) => {
                    videoRef.current.srcObject = stream;
            })
            .catch((error) => {
                console.error('Error al acceder a la cámara:', error);
            });
        };
        setCaptureMode(!captureMode);
        handleHide();
    };

    return (
        <>
            <WarningMessage show={showErrorMessage} message={`Para asegurarte una validación facial exitosa, por favor<strong><span style="color: #eee823;"> retira tus<br />lentes, mascarilla o gorra. </span>Esto nos ayudará a brindarte una<br />experiencia rápida y eficiente. </strong>El sistema te reconoce por medio<br />de la fotografía que aparece en tu DNI.`} onAccept={onAccept} imageicon={imageiconurl} overlayblue='true'></WarningMessage>
            <Modal onShow={handleShow} show={_show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
                <Modal.Header className="custom-modal-header">
                    <Modal.Title>
                        <h5 className="header-container">
                            <img src={Image} className="header-logo" alt="logo" />
                            <span className="header-text">
                                Centra tu rostro en círculo que aparece en la pantalla y presiona 
                                <span className="highlighted-text"> CAPTURAR</span>
                            </span>
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>                    
                    <div className="custom-modal-body">
                        <div className="contenedorcaptura">
                            <video ref={videoRef} autoPlay className={_show ? 'videocapturamirror' : ''} />
                            <div className="backcaptureface"></div>
                        </div>
                        <div className="control-section">
                            <div className="upper-half">
                                <button className="control-button" onClick={handleCapture}>
                                    <div>
                                        <img src={require("../../../../../../../../assets/images/iconcaptura.png")} alt="Capturar" />
                                        <p>{!captureMode ? 'CAPTURAR' : 'Volver a CAPTURAR'}</p>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default FacialCapture;