import React from 'react';
import './LogotipoRnp.css';
import Image from '../../../../assets/images/logotipo-inicio.png';

const LogotipoRnp = () => {

    return (
        <img src={Image} className='welcomelogotipo'/>
    );
};

export default LogotipoRnp;