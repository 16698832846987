import React from 'react';
import './CargadorAnimado.css';

const CargadorAnimado = ({ show }) => {
   
    return (
        <>
            <div className='cargadoranimado'>
                <div className="animationcircle one"></div>
                <div className="animationcircle two"></div>
                <div className="animationcircle three"></div>
                <div className="animationcircle four"></div>
                <div className="animationcircle five"></div>
            </div>
        </>
    );
};

export default CargadorAnimado;