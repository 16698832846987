import React, { useContext,useState } from "react";
import "./ReplacementID.css";
import Image from "../../../../../../assets/images/repodni.png";
import { useHistory } from "react-router-dom";
import AppContext from "../../../../../../commons/components/AppContext/AppContext";
import Wainting from "../../../../../../commons/components/Waiting/Waiting";
import ErrorMessage from "../../../../../../commons/components/ErrorMessage/ErrorMessage";
import imageicon from "../../../../../../assets/images/iconalert.png";
import {isDNIAllowedForFlow, storeBitacora} from "../../../../../../Services/services";

const ReplacementID = ({ _className, text, size }) => {
     const history = useHistory();
     const globalContext = useContext(AppContext);
     const [showWaiting, setShowWaiting] = useState(false);
     const [showErrorMessage, setShowErrorMessage] = useState(false);
     const [messageError, setMessageError] = useState("");

     const onAccept= ()=>{
          setShowErrorMessage(false);
     };

     const handleClick = async () => {
          try {
               setShowWaiting(true);
               const resultValidateDNI=await isDNIAllowedForFlow(globalContext.person?.NumInscripcion);
               console.log("resultValidateDNI",resultValidateDNI);
               setShowWaiting(false);
               if( resultValidateDNI.errorCode==0 || resultValidateDNI.errorCode=="0" ){
                    const bitacora = {
                         RecuperarUltimoDNI: {
                              status: true,
                              data: resultValidateDNI,
                              timestamp: new Date().toISOString(),
                              dni: globalContext.dni,
                         }
                    };
                    await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
                    const compra = {
                         typeDocument: "repodni",
                         certificateId:  globalContext.person?.NumInscripcion,
                         name: globalContext.person?.Nombres,
                         lastName: globalContext.person?.PrimerApellido + " " + globalContext.person?.SegundoApellido,
                         dniSolicitante: globalContext.person?.NumInscripcion,
                    };
                    globalContext.setCompra(compra);
                    history.push(process.env.REACT_APP_BASE_URL + "DNIRepo");
               } else {
                    const bitacora = {
                         RecuperarUltimoDNI: {
                              status: false,
                              data: resultValidateDNI,
                              timestamp: new Date().toISOString(),
                              dni: globalContext.dni,
                         }
                    };
                    await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
                    switch(resultValidateDNI.errorCode){
                         case 1: setMessageError("No es posible continuar. Consultar en oficinas principales del RNP."); break;
                         case 2: setMessageError("No es posible continuar. Ya hay una solicitud de resposición en proceso."); break;
                         case 3: setMessageError("No es posible continuar. No es posible validar la información."); break;
                         default: setMessageError("No hay comunicación.")
                    }
                    setShowErrorMessage(true);
               };
          } catch (error) {
               const bitacora = {
                    RecuperarUltimoDNI: {
                         status: false,
                         data: error,
                         timestamp: new Date().toISOString(),
                         dni: globalContext.dni,
                    }
               };
               await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
               setShowWaiting(false);
               setShowErrorMessage(false);
          };
     };

     return (
          <>
               <div className="CertificateContainer animated animatedFadeInUp fadeInUp dos">
                    <div onClick={handleClick}>
                         <img src={Image} className="certbtnimage" />
                         <p className="textbtnselector">Reposición de <span>Documento Nacional de Identificación</span></p>
                    </div>
               </div>
               <Wainting show={showWaiting}></Wainting>
               <ErrorMessage show={showErrorMessage} message={messageError} onAccept={onAccept} imageicon={imageicon}></ErrorMessage>
          </>
     );
};

export default ReplacementID;
