import React, { useState, useContext, useEffect } from "react";
import "./PreviewMarriageOptions.css";
import { Container, Row, Col } from "react-bootstrap";
import IrMenu from "../../../../commons/components/Buttons/IrMenu/IrMenu";
import Close from "../../../../commons/components/Buttons/Close/Close";
import AppContext from "../../../../commons/components/AppContext/AppContext";
import Process from "../../../../commons/components/Process/Process";
import ErrorMessage from "../../../../commons/components/ErrorMessage/ErrorMessage";
import Animationwaiting from "../../../../assets/images/ImpresionAnimacion.png";
import alertIcon from "../../../../assets/images/iconalert.png";
import { sendToPrinter, storeBitacora } from "../../../../Services/services";
import Invoice from "../../../../commons/components/Invoice/Invoice";
import { useHistory } from "react-router-dom";

const PreviewMarriageOptions = () => {
     const globalContext = useContext(AppContext);
     const [showErrorMessage, setShowErrorMessage] = useState(false);
     const [showProcess, setShowProcess] = useState(false);
     const [msgPrinter,setMsgPrinter]=useState("Su documento <strong>se está</strong><br /><strong>imprimiendo...</strong>");
     const history = useHistory();
     const [invoiceBase64, setInvoiceBase64] = useState(null);
     const [printAttempts, setPrintAttempts] = useState(0);

     useEffect(() => {
          if(invoiceBase64){
               console.log("cert en preview", globalContext.certificate.slice(0, 50));
               sendToPrint(true);
          };
     }, [invoiceBase64]);

     const sendToPrint = async (confirmacion) => {
          setShowProcess(true);
          const result = await sendToPrinter(globalContext.certificate, 2, confirmacion);
          if (result && result?.status) {
               const bitacora = {
                    impresionCertificado2: {
                         status: true,
                         data: result,
                         timestamp: new Date().toISOString(),
                         dni: globalContext.dni,
                    }
               };
               await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora , true ,globalContext.dni);
               console.log("Printer INVOICE");
               setMsgPrinter("Su factura se está imprimiendo...");
               const resultPrintInvoice =await sendToPrinter(invoiceBase64, 3, confirmacion);
               console.log("resultPrintInvoice",resultPrintInvoice);
               if (resultPrintInvoice && resultPrintInvoice?.status) {
                    const bitacora = {
                         impresionCertificado3: {
                              status: true,
                              data: resultPrintInvoice,
                              timestamp: new Date().toISOString(),
                              dni: globalContext.dni,
                         }
                    };
                    await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora , true ,globalContext.dni);
                    history.push(process.env.REACT_APP_BASE_URL + "cierreApp");
                    globalContext.setValidateDNI('');
                    console.log("Go To Cierre");
               }else{
                    const bitacora = {
                         impresionCertificado3: {
                              status: false,
                              data: resultPrintInvoice,
                              timestamp: new Date().toISOString(),
                              dni: globalContext.dni,
                         }
                    };
                    await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora , true ,globalContext.dni);
                    setShowErrorMessage(true);
                    console.log("error imprimiento el recibo");
               };
          } else {
               const bitacora = {
                    impresionCertificado2: {
                         status: false,
                         data: result,
                         timestamp: new Date().toISOString(),
                         dni: globalContext.dni,
                    }
               };
               await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora , true ,globalContext.dni);
               setShowErrorMessage(true);
               console.log("Error en impresion");
          }
          setShowProcess(false);
     };

     const onAccept = () => {
          setShowErrorMessage(false);
          console.log('entro a la function' + printAttempts);
          if (printAttempts < 3) {
               setPrintAttempts(printAttempts + 1);
               sendToPrint(true);
          } else {
               setShowErrorMessage(true);
               console.log("Se han hecho 3 intentos de impresión sin éxito. Redirigiendo a cierreApp");
               history.push(process.env.REACT_APP_BASE_URL + "cierreApp");
               globalContext.setValidateDNI('');
          };
     };
     
     return (
          <>
               <Invoice _setInvoice={setInvoiceBase64}></Invoice>
               <div className="center-screen">
                    <Container>
                         <Row className="align-items-center">
                              <Col>
                                   <div className="alignbtnfooter">
                                        <div className="center">
                                             <IrMenu></IrMenu>
                                             <Close></Close>
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </Container>
                    <Process show={showProcess} imageanimation={Animationwaiting} message={msgPrinter}></Process>
                    <ErrorMessage imageicon={alertIcon} show={showErrorMessage} message={`No se pudo <strong>imprimir el documento. <span style="color: #eee823;">Inténtalo otra vez.</span></strong>`} onAccept={onAccept}></ErrorMessage>
               </div>
          </>
     );
};

export default PreviewMarriageOptions;

{/**
     
                    <Container className="mincertificado">
                         <div className="paddingmarriageuser">
                              <NameUser></NameUser>
                         </div>
                         <Row className="align-items-center">
                              <Col className="izqcertcontfloat">
                                   <PreviewCertPdf _onClick={handleShowPreviewPDF}></PreviewCertPdf>
                              </Col>
                              <Col className="dercertcontfloat">
                                   <ImpresionPdf _onClick={() => sendToPrint(true)}></ImpresionPdf>
                              </Col>
                         </Row>
                         <Row className="align-items-center">
                              <Col>
                                   <div className="alignbtnfooter">
                                        <div className="center">
                                             <IrMenu></IrMenu>
                                             <Close></Close>
                                        </div>
                                   </div>
                              </Col>
                         </Row>
                    </Container>
      */}