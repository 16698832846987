import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import StartActive from "./components/StartActive/StartActive";
import "./ActiveKiosko.css";
import logotipowhite from '../../assets/images/rnplogotipowhite.png';
import Image from "../../assets/images/rnplogotipo.png";
import { Modal } from "react-bootstrap";
import { activatePoint } from "../../Services/services";
import { activatePinPad } from "../../Services/services";
import { savePinPad } from "../../Services/services";
import ErrorMessage from "../../commons/components/ErrorMessage/ErrorMessage";
import Wainting from "../../commons/components/Waiting/Waiting";
import imageiconurl from "../../assets/images/iconalert.png";

const ActiveKiosko = () => {
     const [selectedCamera, setSelectedCamera] = useState("");
     const [selectedFingerprintDevice, setSelectedFingerprintDevice] = useState("");
     const [cameras, setCameras] = useState([]);
     const [fingerprintDevices, setFingerprintDevices] = useState([]);
     const [idKiosko, setIdKiosko] = useState("");
     const [passwordKiosko, setPasswordKiosko] = useState("");
     const [formComplete, setFormComplete] = useState(false);
     const [pinPadSHow, setPinPadSHow] = useState(false);
     const [idPinpad,setIdPinPad]=useState("4");
     const [claIDTran,setClaIDTran]=useState("1");
     const [adminPass,setAdminPass]=useState("123456");
     const [pinpadResult,setPinpadResult]=useState("");
     const [showWaiting, setShowWaiting] = useState(false);
     const [showErrorMessage, setShowErrorMessage] = useState(false);
     const [showModal, setShowModal] = useState(false);
     const history = useHistory();

     useEffect(() => {
          checkCameraPermission();
          checkLocalStorage();
          getDevices();
     }, []);

     useEffect(() => {
          const getFingerprintDevices = localStorage.getItem('allReaders');
          const FingerprintDevices = JSON.parse(getFingerprintDevices);
          console.log(FingerprintDevices);
          setFingerprintDevices(FingerprintDevices);
          if (idKiosko && passwordKiosko && selectedCamera && selectedFingerprintDevice) {
               setFormComplete(true);
          } else {
               setFormComplete(false);
          };
     }, [idKiosko, passwordKiosko, selectedCamera, selectedFingerprintDevice]);

     const checkLocalStorage = () => {
          if (localStorage.getItem("selectedCamera") && localStorage.getItem("selectedFingerprintDevice")) {
               const value1 = localStorage.getItem("selectedCamera");
               const value2 = localStorage.getItem("selectedFingerprintDevice");
               if (value1 && value2) {
                    console.log("Las variables existen y tienen valor:", value1, value2);
                    history.push(process.env.REACT_APP_BASE_URL + "welcome");
               } else {
                    console.log("Al menos una de las variables no tiene valor asignado");
               };
          } else {
               console.log("Al menos una de las variables no existe en el Local Storage");
          };
     };

     const checkCameraPermission = async () => {
          try {
               const stream = await navigator.mediaDevices.getUserMedia({ video: true });
               stream.getTracks().forEach((track) => track.stop());
          } catch (error) {
               console.log("No se pudo acceder a la cámara:", error);
          };
     };

     const getDevices = async () => {
          try {
               const devices = await navigator.mediaDevices.enumerateDevices();
               const videoDevices = devices.filter((device) => device.kind === "videoinput");               
               setCameras(videoDevices);
          } catch (error) {
               console.error("Error al acceder a los dispositivos multimedia:", error);
          };
     };

     const handleCameraChange = (event) => {
          setSelectedCamera(event.target.value);
     };

     const handleFingerprintDeviceChange = (event) => {
          setSelectedFingerprintDevice(event.target.value);
     };

     const pinpadInit = async (event) => {
          console.log("Activando pinpad...");
          event.preventDefault();
          const json={
               "id":idPinpad,
               "claIDTran":claIDTran,
               "AdminPass":adminPass
          };
          setShowWaiting(true);
          let result = await activatePinPad(json);
          console.log("Activando pinpad result",result);
          if (result && result?.status) {
               console.log("respuesta",JSON.stringify(result));
               setPinpadResult(JSON.stringify(result));
               await savePinPad(result);
               history.push(process.env.REACT_APP_BASE_URL + "welcome");
          } else {
               setShowErrorMessage(true);
               setPinpadResult(JSON.stringify(result));
               history.push(process.env.REACT_APP_BASE_URL + "welcome");
          };
          setShowWaiting(false);
     };

     const handleActivateNow = async (event) => {
          event.preventDefault();
          console.log("selectedCamera:", selectedCamera);
          console.log("selectedFingerprintDevice:", selectedFingerprintDevice);
          console.log("idKiosko:", idKiosko);
          console.log("passwordKiosko:", passwordKiosko);
          setShowWaiting(true);
          const response = await activatePoint(idKiosko, passwordKiosko);
          if (response && response.status) {
               console.log("Hay token", response.token);
               localStorage.setItem("tk", response.token);
               setShowWaiting(false);
               setPinPadSHow(true);
          } else {
               setShowWaiting(false);
               setShowErrorMessage(true);
               console.log("No se pudo validar");
          };
          localStorage.setItem("selectedCamera", selectedCamera);
          localStorage.setItem("selectedFingerprintDevice", selectedFingerprintDevice);
          console.log("goto -> Step1");
     };

     const handleCloseModal = () => {
          setShowModal(false);
     };

     const activateKiosko = () => {
          setShowModal(true);
     };
   
     const handleidPinpad = (event) => {
          const newValue = event.target.value.toUpperCase();
          setIdPinPad(newValue);
     };

     const handleclaIDTrand = (event) => {
          const newValue = event.target.value.toUpperCase();
          setClaIDTran(newValue);
     };

     const handleadminPass = (event) => {
          const newValue = event.target.value.toUpperCase();
          setAdminPass(newValue);
     };

     const handleIdKioskoInputChange = (event) => {
          const newValue = event.target.value.toUpperCase();
          setIdKiosko(newValue);
     };

     const handlePasswordChange = (event) => {
          const newValue = event.target.value;
          setPasswordKiosko(newValue);
     };

     const onAccept = () => {
          setShowErrorMessage(false);
     };

     return (
          <div className="activekiosko">
               <div className="contenedorkiosko">
                    <img src={logotipowhite} className='animated animatedFadeInUp fadeInUp'/>;
                    <StartActive text="Activar Kiosko" _handleClick={activateKiosko} _className="btnkioskoactive animated animatedFadeInUp fadeInUp" size="6em"></StartActive>
               </div>
               <Modal show={pinPadSHow} className="backmodalactive" size="lg" centered onHide={handleCloseModal}>
                    <Modal.Header>
                         <Modal.Title>
                              <img src={Image} className="logotipocapture" />
                              <p className="descmodalcapt">PINPAD inicialización</p>
                         </Modal.Title>                         
                    </Modal.Header>
                    <Modal.Body>
                         <p>
                              <b>MS:</b> Autenticación exitosa. A continuación, te presentamos la configuración del pinpad. Por favor, presiona el botón para vincular el dispositivo.
                              <br /> <br />
                              <b>Asegurece que el dispositivo se encuentra conectado.</b>
                         </p>
                         <form>                                  
                              <button onClick={pinpadInit}>Iniciar PINPAD</button> <br/><br/>
                              <div className="form-group" style={{ display: "flex", alignItems: "center" }}>
                                   <input type="text" className="form-control" placeholder="ID" style={{ textTransform: "uppercase" }} value={idPinpad} onChange={handleidPinpad} />
                                   <input type="text" className="form-control" placeholder="claIDTran" style={{ textTransform: "uppercase" }} value={claIDTran} onChange={handleclaIDTrand} />
                                   <input type="password" className="form-control" placeholder="Admin PASS PINDA" style={{ textTransform: "uppercase" }} value={adminPass} onChange={handleadminPass} />
                              </div>
                              <div className="form-group">
                                   <label htmlFor="idKioskoInput">LOG:</label>
                                   <br />
                                   <textarea style={{ width: "100%", height: "500px", borderRadius: "8px", color:"#fff" }} disabled value={pinpadResult} ></textarea>
                              </div>
                         </form>
                    </Modal.Body>
               </Modal>
               <Modal show={showModal} className="backmodalactive" size="lg" centered onHide={handleCloseModal} backdrop="static" >
                    <Modal.Header>
                         <center>
                              <Modal.Title>
                                   <img src={Image} className="logotipocapture" />
                                   <p className="descmodalcapt">Complete los datos para activar este Kiosko</p>
                              </Modal.Title>
                         </center>
                    </Modal.Header>
                    <Modal.Body>
                         <p>
                              <b>IMPORTANTE:</b> Esta configuración solo se realiza una única vez. Si desea reconfigurar estas opciones nuevamente, presione <b>CTRL+SHIFT+Q</b> en el <b>teclado físico</b> en la pantalla de bienvenida del aplicativo.
                         </p>
                         <form>
                              <div className="form-group">
                                   <label htmlFor="idKioskoInput">ID KIOSKO:</label>
                                   <input type="text" className="form-control" id="idKioskoInput" placeholder="Ingrese el ID del kiosko" style={{ textTransform: "uppercase" }} value={idKiosko} onChange={handleIdKioskoInputChange} />
                              </div>
                              <div className="form-group">
                                   <label htmlFor="passwordKioskoInput">CONTRASEÑA KIOSKO:</label>
                                   <input type="password" className="form-control" id="passwordKioskoInput" placeholder="Ingrese la contraseña del kiosko" value={passwordKiosko} onChange={handlePasswordChange} />
                              </div>
                              <div className="form-group">
                                   <label htmlFor="cameraSelect">Dispositivo para reconocimiento facial:</label>
                                   <select className="form-control" id="cameraSelect" onChange={handleCameraChange}>
                                        <option value="">Seleccione una cámara</option>
                                        {cameras.map((camera) => (
                                             <option key={camera.deviceId} value={camera.deviceId}>
                                                  {camera.label}
                                             </option>
                                        ))}
                                   </select>
                              </div>
                              <div className="form-group">
                                   <label htmlFor="fingerprintDeviceSelect">Dispositivo para lectura de huella:</label>
                                   <select className="form-control" id="fingerprintDeviceSelect" onChange={handleFingerprintDeviceChange}>
                                        <option value="">Seleccione una lector de huellas</option>
                                        <option value="UareU">UareU</option>
                                        {fingerprintDevices?.map((finger) => (
                                             <option key={finger} value={finger}>
                                                  {finger}
                                             </option>
                                        ))}
                                   </select>
                              </div>
                              <button onClick={handleActivateNow} disabled={!formComplete} style={{ visibility: "visible" }}>
                                   CONTINUAR
                              </button>
                         </form>
                    </Modal.Body>
               </Modal>
               <Wainting show={showWaiting}></Wainting>
               <ErrorMessage show={showErrorMessage} message="No se pudo Acvtivar el KIOSKO, valide los datos ingresados." onAccept={onAccept} imageicon={imageiconurl}></ErrorMessage>
          </div>
     );
};

export default ActiveKiosko;
