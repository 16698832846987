import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../commons/components/AppContext/AppContext";
import "./Welcome.css";
import StartButton from "./components/StartButton/StartButton";
import LogotipoRnp from "./components/LogotipoRnp/LogotipoRnp";

const Welcome = () => {
    const globalContext = useContext(AppContext);
    const history = useHistory();
    const [isVideoActive, setIsVideoActive] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [audioUrl, setAudioUrl] = useState('');
    const [videoError, setVideoError] = useState(false);    

    const clearLocalStorage = () => {
        if (localStorage.getItem("selectedCamera")) {
            localStorage.removeItem("selectedCamera");
        };
        if (localStorage.getItem("selectedFingerprintDevice")) {
            localStorage.removeItem("selectedFingerprintDevice");
        };
        if (localStorage.getItem("tk")) {
            localStorage.removeItem("tk");
        };
    };

    useEffect(() => {
        globalContext.setayuda(true);
        globalContext.setnacimiento(false);
        let timeout;
        const startInactivityTimer = () => {
            timeout = setTimeout(() => {
                fetch(`${process.env.REACT_APP_API_URL}/download/publicidad.mp4`)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('No se pudo descargar el video');
                        }
                        return response.blob();
                    })
                    .then(blob => {
                        const url = URL.createObjectURL(blob);
                        setVideoUrl(url);
                        setIsVideoActive(true);
                    })
                    .catch(error => {
                        console.error('Error al obtener el video:', error);
                        setVideoError(true);
                        setIsVideoActive(false);
                    });
            }, 5000);
        };
        const downloadAudio = () => {
            fetch(`${process.env.REACT_APP_API_URL}/download/inicio.mp3`)
                .then(response => response.blob())
                .then(blob => {
                    const url = URL.createObjectURL(blob);
                    setAudioUrl(url);
                })
                .catch(error => console.error('Error al obtener el audio:', error)
            );
        };
        const resetTimeout = () => {
            clearTimeout(timeout);
            setIsVideoActive(false);
            startInactivityTimer();
        };
        const handleKeyPress = (e) => {
            if (e.ctrlKey && e.shiftKey && e.key === "Q") {
                clearLocalStorage();
                history.push(process.env.REACT_APP_BASE_URL);
            };
        };
        downloadAudio();
        window.addEventListener("mousemove", resetTimeout);
        window.addEventListener("click", resetTimeout);
        window.addEventListener("keydown", resetTimeout);
        document.addEventListener("keydown", handleKeyPress);
        startInactivityTimer();
        return () => {
            clearTimeout(timeout);
            window.removeEventListener("mousemove", resetTimeout);
            window.removeEventListener("click", resetTimeout);
            window.removeEventListener("keydown", resetTimeout);
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [history]);

    return (
        <div className="welcome">
            <div>
                {isVideoActive && !videoError ? (
                    <div style={{ marginTop: '10%' }}>
                        <video
                            width="100%"
                            height="100%"
                            autoPlay
                            loop
                            src={videoUrl}
                            type="video/mp4"
                        >
                            Tu navegador no soporta la etiqueta de video.
                        </video>
                    </div>
                ) : (
                    <div>
                        <div>
                            <LogotipoRnp />
                        </div>
                        <div>
                            <StartButton text="COMENCEMOS" _className="animated animatedFadeInUp fadeInUp" />
                        </div>
                        {audioUrl && (
                            <audio autoPlay>
                                <source src={audioUrl} type="audio/mp3" />
                                Tu navegador no soporta la etiqueta de audio.
                            </audio>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Welcome;