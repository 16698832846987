import React, { useEffect, useState, useContext } from 'react';
import './Places.css';
import { getListPlacesPickup, storeBitacora } from '../../../Services/services';
import CargadorAnimado from '../CargadorAnimado/CargadorAnimado';
import AppContext from "../../../commons/components/AppContext/AppContext";
import imageicon from "../../../assets/images/LugaresEntrega.png";

const Places = ({ show, setCodigoEntrega, setShowPlaces, CodigoEntrega }) => {
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const globalContext = useContext(AppContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const placesData = await getListPlacesPickup();
        const placesParse = JSON.parse(placesData);
        const places = placesParse.lst_LugaresEntregaDNIResponse.infoUbicacionesEntrega;
        const placesArray = Array.isArray(places) ? places : [places];
        const bitacora = {
          lst_LugaresEntregaDNI: {
            status: true,
            data: placesArray,
            timestamp: new Date().toISOString(),
            dni: globalContext.dni,
          }
        };
        await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
        const uniquePlaces = placesArray.filter((item, index, array) => {
          const currentCode = item.infoUbicaciones.Codigolugarentrega;
          return array.findIndex((place) => place.infoUbicaciones.Codigolugarentrega === currentCode) === index;
        });
        setPlaces(uniquePlaces);
        setLoading(false);
      } catch (error) {
        const bitacora = {
          lst_LugaresEntregaDNI: {
            status: false,
            data: error,
            timestamp: new Date().toISOString(),
            dni: globalContext.dni,
          }
        };
        await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
        console.error('Error al obtener la lista de lugares de recogida:', error);
        setLoading(false);
      };
    };
    fetchData();
  }, []);

  const savePlaceCode = (codigo) => {
    globalContext.setcodigoEntrega(codigo);
    setCodigoEntrega(codigo);
    setShowPlaces(false);
    console.log(codigo);
  };

  if (!show) {
    return null;
  };

  return (
    <div className='error-overlay2'>
      {loading ? (
          <div>
              <h3 style={{color:'white'}}>Cargando lista de lugares disponibles...</h3>
              <div className="center-screen">
                <CargadorAnimado></CargadorAnimado>
              </div>
          </div>
        ) : (
        <div className='places-content'>          
          <img src={imageicon} alt="Alert" />
          <div>
            <h3>Por favor, <strong>selecciona el lugar<br/>donde deseas retirar tu DNI..</strong></h3>
          </div>
          <div className={places.length > 4 ? "places-scroll" : ""}>
            {places.length === 0 ? (
              <div>
                <div className='places-content-button' onClick={() => savePlaceCode('0801OFCPRIC')}>
                  <div>
                    <h4>OFICINAS PRINCIPALES<br/><span>Tegucigalpa - Torre Futura</span></h4>
                  </div>
                </div>
                <br />
                <div className='places-content-button' onClick={() => savePlaceCode('0501PLAZAUNO')}>
                  <div>
                    <h4>PLAZA UNO<br/><span>San Pedro Sula - Salida a la Limas</span></h4>
                  </div>
                </div>
              </div>
            ) : (
              places.map((item, index) => {
                const place = item.infoUbicaciones;
                return (
                  <div key={index}>
                    <div className='places-content-button'  onClick={() => savePlaceCode(place.Codigolugarentrega)}>
                      <div>
                        <h4>{place.Descripcionlugarentrega}<br/><span>{place.Direccionlugarentrega}</span></h4>
                      </div>
                    </div>
                    <br />
                  </div>
                );
              })
            )}
          </div>
        </div>
      )}
    </div>    
  );
};

export default Places;