import React, { useContext } from "react";
import "./PersonalCertificate.css";
import Image from "../../../../../../assets/images/iconopersona.png";
import { useHistory } from "react-router-dom";
import AppContext from "../../../../../../commons/components/AppContext/AppContext";
import { invokeCBirth } from "../../../../../../Services/InvokeServices";
import { updateAsDelivered, storeBitacora } from "../../../../../../Services/services";

const PersonalCertificate = ({ setShowErrorMessage, setShowWaiting }) => {
    const history = useHistory();
    const globalContext = useContext(AppContext);
    const dni = globalContext.dni;

    const handleClick = async () => {
        setShowWaiting(true);
        try {
            const certificateResult = await invokeCBirth(globalContext.person.NumInscripcion, globalContext.person.NumInscripcion);
            const modifiedCertificateResult = {
                ...certificateResult,
                response: {
                    ...certificateResult.response,
                    Certificado: certificateResult.response.Certificado.slice(0, 50),
                },
            };
            if (certificateResult.errorCode === "00" && certificateResult.response.DetalleError === "") {
                const bitacora = {
                    qry_CertificadoNacimiento: {
                        status: true,
                        data: modifiedCertificateResult,
                        timestamp: new Date().toISOString(),
                        dni: dni,
                    },
                };
                await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, dni);
                try {
                    const responseUpdateDelivered = await updateAsDelivered(certificateResult.lastID);
                    const bitacoraDelivery = {
                        updateAsDelivered: {
                            status: true,
                            data: responseUpdateDelivered,
                            timestamp: new Date().toISOString(),
                            dni: dni,
                        },
                    };
                    await storeBitacora(JSON.stringify(bitacoraDelivery), globalContext.id_bitacora, true, dni);
                } catch (error) {
                    const errorMessage = error.response?.data?.message || error.message || 'Error desconocido';
                    const bitacoraUpdateAsDelivered = {
                        updateAsDelivered: {
                            status: false,
                            data: errorMessage,
                            timestamp: new Date().toISOString(),
                            dni: dni,
                        },
                    };
                    await storeBitacora(JSON.stringify(bitacoraUpdateAsDelivered), globalContext.id_bitacora, true, dni);
                    setShowErrorMessage(true);
                };
                setShowWaiting(false);
                globalContext.setCertificate(certificateResult.response.Certificado);
                history.push(process.env.REACT_APP_BASE_URL + "previewCertificate");
            } else {
                const bitacora = {
                    qry_CertificadoNacimiento: {
                        status: false,
                        data: modifiedCertificateResult,
                        timestamp: new Date().toISOString(),
                        dni: dni,
                    },
                };
                await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, dni);
                setShowErrorMessage(true);
                setShowWaiting(false);
            };
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || 'Error desconocido';
            const bitacoraInvokeCBirth = {
                qry_CertificadoNacimiento: {
                    status: false,
                    data: errorMessage,
                    timestamp: new Date().toISOString(),
                    dni: dni,
                },
            };
            await storeBitacora(JSON.stringify(bitacoraInvokeCBirth), globalContext.id_bitacora, true, dni);
            setShowErrorMessage(true);
            setShowWaiting(false);
        };
    };

    return (
        <div className="contenedorcertificado animated animatedFadeInUp fadeInUp" onClick={handleClick}>
            <a className="contbtncerthor">
                <p className="textbtncert"><img src={Image} className="" alt="Persona Icon" />Personal</p>
            </a>
        </div>
    );
};

export default PersonalCertificate;