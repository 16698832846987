import React from 'react';
import './DocumentsOptions.css';
import { Container, Row, Col } from 'react-bootstrap';
import BirthCertificate from './components/BirthCertificate/BirthCertificate';
import MarriageCertificate from './components/MarriageCertificate/MarriageCertificate';
import DeathCertificate from './components/DeathCertificate/DeathCertificate';
import ReplacementID from './components/ReplacementID/ReplacementID';
import NameUser from '../../../../commons/components/UserNames/NameUser/NameUser';
import Close from '../../../../commons/components/Buttons/Close/Close';
import LogotipoRnp from '../../../../commons/components/Logotipo/LogotipoRnp/LogotipoRnp';


const DocumentsOptions = ({setShowErrorMessage,setShowWaiting}) => {
    return (
        <> 
           <div className="center-screen">
                <Container>
                    <LogotipoRnp></LogotipoRnp>
                    <NameUser type = 'Selector'></NameUser>
                    <div className='animated animatedFadeInUp fadeInUp messageSelect'>
                        <h5>Selecciona la gestión que deseas realizar:</h5>
                    </div>
                    <Row className="align-items-center">
                        <Col md={3}>
                            <BirthCertificate setShowErrorMessage={setShowErrorMessage} setShowWaiting={setShowWaiting}></BirthCertificate>
                        </Col>
                        <Col md={3}>
                            <MarriageCertificate setShowErrorMessage={setShowErrorMessage} setShowWaiting={setShowWaiting}></MarriageCertificate>
                        </Col>
                        <Col md={3}>
                            <DeathCertificate  setShowErrorMessage={setShowErrorMessage} setShowWaiting={setShowWaiting}></DeathCertificate>
                        </Col>
                        <Col md={3}>
                            <ReplacementID  setShowErrorMessage={setShowErrorMessage} setShowWaiting={setShowWaiting}></ReplacementID>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col>
                            <Close></Close>
                        </Col>
                    </Row>
                </Container>             
            </div>
        </>
    );
};

export default DocumentsOptions;