import React , {useState}from 'react';
import GlobalTitleCertificate from '../../commons/components/TitulosCertificados/GlobalTitleCertificateVertical/GlobalTitleCertificateVertical';
import ImageNacimiento from '../../assets/images/certnacimiento.png';
import './DescendenciaCertificate.css';
import DocumentsOptions from './components/DocumentsOptions/DescendenciaCertificateOptions';
import LogotipoRnp from '../../commons/components/Logotipo/LogotipoRnp/LogotipoRnp';
import Wainting from '../../commons/components/Waiting/Waiting';
import ErrorMessage from '../../commons/components/ErrorMessage/ErrorMessage';
import imageicon from "../../assets/images/iconalert.png"
import NameUser from "../../commons/components/UserNames/NameUser/NameUser";
import { Container, Row, Col } from 'react-bootstrap';
import IrMenu from "../../commons/components/Buttons/IrMenu/IrMenu";
import Close from "../../commons/components/Buttons/Close/Close";

const DescendenciaCertificate = () => {  
    const [showWaiting, setShowWaiting] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const onAccept= ()=>{
        setShowErrorMessage(false);
    };

    return (
        <div>
            <div className="contenedortitle">
                <LogotipoRnp></LogotipoRnp>
                <NameUser></NameUser>
                <div className='animated animatedFadeInUp fadeInUp messageSelect'>
                    <p style={{ marginTop: '-40px' }}>Selecciona el Certificado del familiar que deseas imprimir</p>
                </div>                
                <Container>
                    <Row className="align-items-center">
                    <Col>
                        <GlobalTitleCertificate imgcertificado={ImageNacimiento} nameligth="Certificado de" namebold="" nameorange="Nacimiento" ></GlobalTitleCertificate>
                    </Col>
                    <Col>
                        <DocumentsOptions setShowErrorMessage={setShowErrorMessage} setShowWaiting={setShowWaiting} showErrorMessage={showErrorMessage}>  </DocumentsOptions>
                    </Col>
                    </Row>
                    <Row className="align-items-center">
                    <Col>
                        <div className="alignbtnfooter">
                            <div className="center">                                
                                <IrMenu></IrMenu>
                                <Close></Close>
                            </div>
                        </div>
                    </Col>                 
                    </Row>  
                </Container>
                <Wainting show={showWaiting}></Wainting>
                <ErrorMessage imageicon={imageicon}  show={showErrorMessage} message={`No se pudo <strong>obtener el certificado. <span style="color: #eee823;">Inténtalo otra vez.</span></strong>`} onAccept={onAccept} ></ErrorMessage>
            </div>
        </div>
    );
};

export default DescendenciaCertificate;