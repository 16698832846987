import React, { useState, useEffect, useContext } from "react";
import "./DescendenciaCertificateOptions.css";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import AppContext from "../../../../commons/components/AppContext/AppContext";
import { invokeCBirth } from "../../../../Services/InvokeServices";
import { updateAsDelivered, storeBitacora } from "../../../../Services/services";

const DescendenciaCertificateOptions = ({ showErrorMessage, setShowWaiting , setShowErrorMessage }) => {
    const [data, setData] = useState([]);
    const history = useHistory();
    const globalContext = useContext(AppContext);

    useEffect(() => {
        setData(globalContext.hijos);
    }, [globalContext.hijos]);

    const handleClick = async (NumInscripcion) => {
        setShowWaiting(true);
        try {
            console.log("REVISANDO HIJO");
            const certificateResult = await invokeCBirth(NumInscripcion, globalContext.person.NumInscripcion);
            const modifiedCertificateResult = {
                ...certificateResult,
                response: {
                    ...certificateResult.response,
                    Certificado: certificateResult.response.Certificado.slice(0, 50)
                }
            };
            if (certificateResult.errorCode === "00" && certificateResult.response.DetalleError === "") {
                const bitacora = {
                    qry_CertificadoNacimiento: {
                        status: true,
                        data: modifiedCertificateResult,
                        timestamp: new Date().toISOString(),
                        dni: globalContext.dni,
                    }
                };
                await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
                try {
                    const responseUpdateDelivered = await updateAsDelivered(certificateResult.lastID);
                    const bitacoraDelivered = {
                         updateAsDelivered: {
                            status: true,
                            data: responseUpdateDelivered,
                            timestamp: new Date().toISOString(),
                            dni: globalContext.dni,
                        }
                    };
                    await storeBitacora(JSON.stringify(bitacoraDelivered), globalContext.id_bitacora, true, globalContext.dni);
                } catch (error) {
                    const bitacoraError = {
                         updateAsDelivered: {
                            status: false,
                            data: error,
                            timestamp: new Date().toISOString(),
                            dni: globalContext.dni,
                        }
                    };
                    await storeBitacora(JSON.stringify(bitacoraError), globalContext.id_bitacora, true, globalContext.dni);
                    setShowErrorMessage(true);
                };
                setShowWaiting(false);
                globalContext.setCertificate(certificateResult.response.Certificado);
                history.push(process.env.REACT_APP_BASE_URL + "previewCertificate");
            } else {
                const bitacora = {
                    qry_CertificadoNacimiento: {
                        status: false,
                        data: modifiedCertificateResult,
                        timestamp: new Date().toISOString(),
                        dni: globalContext.dni,
                    }
                };
                await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
                setShowWaiting(false);
            };
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || 'Error desconocido';
            const bitacora = {
                qry_CertificadoNacimiento: {
                    status: false,
                    data: errorMessage,
                    timestamp: new Date().toISOString(),
                    dni: globalContext.dni,
                }
            };
            await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
            setShowWaiting(false);
            setShowErrorMessage(true);
        };
    };

    return (
        <div className="center-screen">
            <Container>
                <Row className="align-items-center animated animatedFadeInUp fadeInUp">
                    <Col>
                        <div>
                            <div className="contenedorscroll">
                                <ul className="ulcontent">
                                    {data.map((item, index) => (
                                        <li key={index}>
                                            <img className="icondescarga" src={require("../../../../assets/images/icondescargar.png")} alt="Descarga" />
                                            <div className="contentparent">
                                                <h2>{`${item.Nombres} ${item.PrimerApellido} ${item.SegundoApellido}`}</h2>
                                                <p>{item.Sexo === "M" ? "Hijo" : "Hija"}</p>
                                            </div>
                                            <a onClick={() => handleClick(item.NumInscripcion)}>Imprimir Certificado</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default DescendenciaCertificateOptions;