console.originalLog = console.log;
var logCounter = 0;

console.log = function (...args) {
     const isDebug = process.env.REACT_APP_IS_DEBUG === "true";

     if (isDebug) {
          logCounter++;
          console.originalLog(`Log ${logCounter}:`, ...args);
     }
};
