import React from 'react';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import './Home.css';
import ActiveKiosko from '../ActiveKiosko/ActiveKiosko';
import Welcome from '../Welcome/Welcome';
import Authentication from '../Authentication/Authentication';
import DocumentSelector from '../DocumentSelector/DocumentSelector';
import CertificateSelector from '../CertificateSelector/CertificateSelector';
import PreviewCertificate from '../PreviewCertificate/PreviewCertificate';
import DescendenciaCertificate from '../DescendenciaCertificate/DescendenciaCertificate';
import CertificateMarriage from '../CertificateMarriage/CertificateMarriage';
import PreviewMarriage from '../PreviewMarriage/PreviewMarriage';
import SearchDeath from '../SearchDeath/SearchDeath';
import DeathPreview from '../PreviewDeath/PreviewDeath';
import PayMarriage from '../CertificateMarriage/components/PayMarriage/PayMarriage';
import Cierre from '../Cierre/Cierre';
import PayDeath from '../SearchDeath/components/PayDeath/PayDeath';
import DNIRepo from '../DNIRepo/DNIRepo';
import PreviewDNIRepo from '../DNIRepo/components/PreviewDNIRepo/PreviewDNIRepo';
import AlertError from '../Alerta/Alerta';
import EnvSignal from './EnvSignal/EnvSignal';
import TopBar from './Navbar/TopBar';

const Home = () => {
    const isDebug = process.env.REACT_APP_IS_DEBUG === 'true';
    const location = useLocation();
    const isNoTopBarRoute = location.pathname === process.env.REACT_APP_BASE_URL;

    return (
        <>
            {isDebug && <EnvSignal />}
            <Container fluid>
                {!isNoTopBarRoute && <TopBar />}                
                <Row className={!isNoTopBarRoute ? 'contenedor' : ''}>
                    <Switch>
                        <Route exact path={process.env.REACT_APP_BASE_URL} component={ActiveKiosko} />
                        <Route exact path={process.env.REACT_APP_BASE_URL + "welcome"} component={Welcome} />
                        <Route exact path={process.env.REACT_APP_BASE_URL + "authentication"} component={Authentication} />
                        <Route exact path={process.env.REACT_APP_BASE_URL + "documentSelector"} component={DocumentSelector} />
                        <Route exact path={process.env.REACT_APP_BASE_URL + "certificateSelector"} component={CertificateSelector} />
                        <Route exact path={process.env.REACT_APP_BASE_URL + "previewCertificate"} component={PreviewCertificate} />
                        <Route exact path={process.env.REACT_APP_BASE_URL + "decencyCertificate"} component={DescendenciaCertificate} />
                        <Route exact path={process.env.REACT_APP_BASE_URL + "marriageCertificate"} component={CertificateMarriage} />
                        <Route exact path={process.env.REACT_APP_BASE_URL + "marriagePay"} component={PayMarriage} />
                        <Route exact path={process.env.REACT_APP_BASE_URL + "marriagePreview"} component={PreviewMarriage} />
                        <Route exact path={process.env.REACT_APP_BASE_URL + "searchDeath"} component={SearchDeath} />
                        <Route exact path={process.env.REACT_APP_BASE_URL + "payDeath"} component={PayDeath} />
                        <Route exact path={process.env.REACT_APP_BASE_URL + "deathPreview"} component={DeathPreview} />
                        <Route exact path={process.env.REACT_APP_BASE_URL + "DNIRepo"} component={DNIRepo} />
                        <Route exact path={process.env.REACT_APP_BASE_URL + "previewdnirepo"} component={PreviewDNIRepo} />
                        <Route exact path={process.env.REACT_APP_BASE_URL + "alertError"} component={AlertError} />
                        <Route exact path={process.env.REACT_APP_BASE_URL + "cierreApp"} component={Cierre} />                               
                    </Switch>
                </Row>
            </Container>
        </>
    );
};

const App = () => (
    <BrowserRouter>
        <Home />
    </BrowserRouter>
);

export default App;