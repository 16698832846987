import React,{ useContext } from 'react';
import './FacialRecognitionButton.css';
import FacialCapture from './componets/FacialCapture/FacialCapture';
import AppContext from '../../../../../../commons/components/AppContext/AppContext';

const FacialRecognitionButton = ({_onClick}) => {    
    const globalContext = useContext(AppContext);
    const vari1 = globalContext.failedFingerprintAttempts;
    console.log(vari1);
    const activeClass = `contenedorbtnautentica ${!globalContext.validateDNI || globalContext.failedFingerprintAttempts < 3 ? 'btnfacialdisable' : ''}`;
    const imageClass = `${!globalContext.validateDNI || globalContext.failedFingerprintAttempts < 3 ? 'btnDisableFaceprint' : 'btnEnableFaceprint'}`;

    const activeButton = () => {
        if (globalContext.validateDNI && globalContext.failedFingerprintAttempts >= 3) {
            globalContext.handleClickModalFacial();
        };
    };

    return (
        <>        
            <div className={`FaceContainer animated animatedFadeInUp fadeInUp`}  onClick={activeButton}>
                <div className={activeClass}>
                    <div className={imageClass}></div>
                    <span className="btnselectautentica">Reconocimiento Facial</span>
                </div>                            
            </div>
            <FacialCapture _onClick={_onClick}></FacialCapture>
        </>
    );
};

export default FacialRecognitionButton;
