import React from 'react';
import './Process.css';
import CargadorAnimado from '../../components/CargadorAnimado/CargadorAnimado';

const Process = ({ show, message, imageanimation }) => {
   
    if (!show) {
        return null;
    };

    return (
        <>
            <div className='waiting-overlay'>
                <div className='waiting-content'>
                    <img src={imageanimation} alt="Alert"/>
                    <div className='loading-text' dangerouslySetInnerHTML={{ __html: message }}/>
                    <CargadorAnimado>  </CargadorAnimado>
                </div>
            </div>
        </>
    );
};

export default Process;