import React, { useState, useContext, useEffect } from "react";
import "./PayDeath.css";
import GeneralPay from "../../../../commons/components/GeneralPay/GeneralPay";
import certdefuncion from "../../../../assets/images/certdefuncion.png";
import { useHistory } from "react-router-dom";
import AppContext from "../../../../commons/components/AppContext/AppContext";
import Wainting from "../../../../commons/components/Waiting/Waiting";
import { checkout, checkoutCode, validateStatusTGR ,updateAsDelivered } from "../../../../Services/services";
import { invokeCreateTGR } from "../../../../Services/InvokeServices";
import Animationwaiting from "../../../../assets/images/PagosAnimacion.png";
import InstruccionesPago from "../../../../assets/images/instruccionesPago.png";
import Process from "../../../../commons/components/Process/Process";
import { getDataPinPad, sentToEM, storeBitacora } from "../../../../Services/services";
import icoOk from "../../../../assets/images/iconcheck.png";
import ErrorMessage from "../../../../commons/components/ErrorMessage/ErrorMessage";
import ErrorMessageAuto from "../../../../commons/components/ErrorMessage/ErrorMessageAuto";
import icoF from "../../../../assets/images/iconalert.png";
import iconGetTGR from "../../../../assets/images/iconsearch.png"
import icoB from "../../../../assets/images/reintentandoicono.png";

const PayDeath = () => {
     const [showWaiting, setShowWaiting] = useState(false);
     const [showWaitingGetTGR, setShowWaitingGetTGR] = useState(false);
     const [showMessageWaitingGetTGR, setShowMessageWaitingGetTGR] = useState('Un momento por favor. Obteniendo<br/>el <strong>costo</strong> del documento...');
     const [showErrorMessage, setShowErrorMessage] = useState(false);
     const globalContext = useContext(AppContext);
     const [receivedMessage, setReceivedMessage] = useState("");
     const [icon, setIcon] = useState(InstruccionesPago);
     const [proccessPayMsg, setProccessPayMsg] = useState("");
     const [paymentResponsePinPad, setPaymentResponsePinPad] = useState("");
     const [retryCounter, setRetryCounter] = useState(0);
     const [showErrorPaymentMessage, setShowErrorPaymentMessage] = useState(false);
     const [resultCreateTGR, setResultCreateTGR] = useState(null);
     const [showErrorMessageUpdate, setShowErrorMessageUpdate] = useState(false);
     const [showErrorAmountMessage, setShowErrorAmountMessage] = useState(false);
     const [showMessageTGR, setShowMessageTGR] = useState(false);
     const [messageErrorTGR, setMessageErrorTGR] = useState('El TGR no es valido')
     const [receivedMessageUpdate, setReceivedMessageUpdate] = useState("");
     const [beforeShowAlertError, setBeforeShowAlertError] = useState(false);
     const [beforeMessageAlertError, setBeforeMessageAlertError] = useState("El kiosco estará <strong>temporalmente en mantenimiento</strong><br/>debido a problemas de comunicación.");
     const [lastID, setLastID] = useState(0);
     const history = useHistory();

     useEffect(() => {
          const fetchTGR = async () => {
               setShowWaitingGetTGR(true);
               const objCreateTGR = {
                    dniSolicitante: globalContext.compra.dniSolicitante
               };
               console.log("objCreateTGR",objCreateTGR);
               try {
                    const result=await invokeCreateTGR(objCreateTGR);
                    if(result.result.monto == 0 || result.result.monto == "0"){
                         const bitacora = {
                              crearReciboTGR1: {
                                   status: false,
                                   data: result,
                                   timestamp: new Date().toISOString(),
                                   dni: globalContext.dni,
                              }
                         };
                         await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
                         setShowWaitingGetTGR(false);
                         setShowErrorAmountMessage(true);
                    } else {
                         const bitacora = {
                              crearReciboTGR1: {
                                   status: true,
                                   data: result,
                                   timestamp: new Date().toISOString(),
                                   dni: globalContext.dni,
                              }
                         };
                         await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
                         console.log("Resultado Crear TGR",result);
                         setResultCreateTGR(result);
                         setShowWaitingGetTGR(false);
                         console.log("Resultado Crear TGR",result);
                    };
               } catch (error) {
                    const bitacora = {
                         crearReciboTGR1: {
                              status: false,
                              data: error,
                              timestamp: new Date().toISOString(),
                              dni: globalContext.dni,
                         }
                    };
                    await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
                    setShowWaitingGetTGR(false)
                    console.error('Error al invocar createTGR:', error);
                    setShowErrorAmountMessage(true);
               };
          };
          fetchTGR();
     }, [globalContext.compra.dniSolicitante]);

     function formatAmount(amount) {
          if (!isNaN(parseFloat(amount)) && isFinite(amount)) {
               return amount + "00";
          } else {
               console.error("El monto proporcionado no es un número válido");
               return amount;
          };
     }

     const sendToSocket = async () => {
          try {
               const result = await getDataPinPad();
               console.log("result getDataPinPad", result);
               if (result && result?.status) {
                    const bitacora = {
                         getDataPinPad: {
                              status: true,
                              data: result,
                              timestamp: new Date().toISOString(),
                              dni: globalContext.dni,
                         }
                    };
                    await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
                    const amount = formatAmount(resultCreateTGR?.result.monto);
                    const jsonData = {
                         id: 1,
                         claIDTran: result.data.claIDTran,
                         monto: amount,
                         last4: "",
                         expDate: "",
                         Currency: "1",
                         PayEntryMode: "7",
                         Tax1: "0",
                         Tax2: "0",
                         Discount: "0",
                         Tip: "0",
                         BaseAmount: amount,
                    };
                    console.log("PinpadJSON",jsonData);
                    const resultPay = await sentToEM(jsonData);
                    console.log("resultPay ", resultPay);
                    return resultPay;
               } else {
                    const bitacora = {
                         getDataPinPad: {
                              status: false,
                              data: result,
                              timestamp: new Date().toISOString(),
                              dni: globalContext.dni,
                         }
                    };
                    await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
                    return null;
               };
          } catch (error) {
               const bitacora = {
                    getDataPinPad: {
                         status: false,
                         data: error,
                         timestamp: new Date().toISOString(),
                         dni: globalContext.dni,
                    }
               };
               await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
               setShowWaiting(false);
          };
     };

     const middleMessage = () => {
          const timer = setTimeout(() => {
               setProccessPayMsg("Agradecemos su espera...");
          }, 40000);
     };

     const sleep = (ms) => {
          return new Promise(resolve => setTimeout(resolve, ms));
     };

     let contador = 0;

     async function setDocumentAsDelivered() {
          let maxRetries = 6;      
          while (contador < maxRetries) {
               try {
                    setProccessPayMsg("Reintentando, <br/>por favor espere...");
                    setIcon(icoB);
                    setShowWaiting(true);
                    await updateAsDelivered(lastID);
                    setShowWaiting(false);
                    history.push(process.env.REACT_APP_BASE_URL + "deathPreview");
                    console.log("El documento se ha marcado como entregado correctamente.");
                    return;
               } catch (error) {
                    console.error("Error al marcar el documento como entregado:", error);
                    setShowErrorMessageUpdate(false);
                    contador++;          
                    if (contador < maxRetries) {
                         console.log(`Reintentando en 5 segundos... Intento ${contador} de ${maxRetries}`);
                         await sleep(5000);
                    } else {
                         setBeforeShowAlertError(true);
                         break;
                    };
               };
          };
     };
     
     const tryTogetDataFromRNP = async (jsonData, retry = false) => {
          let response;
          try {
               if (retry) {
                    setProccessPayMsg("Reintentando, <br/>por favor espere...");
                    setIcon(icoB);
                    middleMessage();
               } else {
                    setProccessPayMsg("<strong>¡Pago exitoso! </strong><br/>por favor espere...");
                    setIcon(icoOk);
                    middleMessage();
               };
               const fullName=globalContext.compra?.name+" "+globalContext.compra?.lastName;
               globalContext.setResponseVPOS({...jsonData,fullName:fullName,description:'Acta de defunción'});
               let obj = {
                    responseVpos: jsonData,
                    compra: globalContext.compra,
               };
               console.log("Obj a checkout", obj);
               response = await checkout(obj,resultCreateTGR, globalContext.id_bitacora);
               if (response != false) {
                    globalContext.setCertificate(response.response);
                    const responseUpdateDelivered = await updateAsDelivered(response.lastID);
                    const bitacoraDelivered = {
                         updateAsDelivered: {
                              status: true,
                              data: responseUpdateDelivered,
                              timestamp: new Date().toISOString(),
                              dni: globalContext.dni,
                         }
                    };
                    await storeBitacora(JSON.stringify(bitacoraDelivered), globalContext.id_bitacora, true, globalContext.dni);
                    setShowWaiting(false);
                    history.push(process.env.REACT_APP_BASE_URL + "deathPreview");
               } else {
                    setShowWaiting(false);
                    console.log("response", response);
                    if (retryCounter >= 6) {
                         setBeforeShowAlertError(true);
                         return;
                    };
                    setReceivedMessage("Hubo un <strong>error validando su pago contra el RNP.</strong>");
                    setShowErrorMessage(true);
               }
               console.log("response", response);
          } catch (error) {
               if (response && response !== false && response.lastID) {
                    console.log('Si se pago pero no se actualizo el updateDelivery');
                    setShowErrorMessageUpdate(true);
                    setReceivedMessageUpdate(`Hubo un <strong>error al procesar su solicitud. <span style="color: #eee823;">Inténtalo otra vez.</span></strong>`);
                    globalContext.setCertificate(response.response);
                    setLastID(response.lastID);
                    setShowWaiting(false);
               } else {
                    setShowWaiting(false);
                    setReceivedMessage("Hubo un <strong>error validando su pago contra el RNP.</strong>");
                    setShowErrorMessage(true);
                    if (retryCounter >= 6) {
                         setBeforeShowAlertError(true);
                         return;
                    };
               };
          };
     };

     const payNow = async () => {
          const amount = formatAmount(resultCreateTGR?.result.monto);
          if(amount == 0 || amount == '' || amount == undefined){
               setShowErrorAmountMessage(true);
               return;
          };
          setIcon(InstruccionesPago);
          setProccessPayMsg("");
          setShowWaiting(true);
          console.log("Enviando PAGO al PIN PAD");
          await new Promise(resolve => setTimeout(resolve, 20000));
          setIcon(Animationwaiting);
          await new Promise(resolve => setTimeout(resolve, 500));
          setProccessPayMsg("Por favor, <strong>acerca tu tarjeta<br/>al POS</strong> cuando la luz esté<br/>verde");
          const jsonData = await sendToSocket();
          if ( jsonData == null || jsonData.status === false || jsonData.id === 0 || (jsonData.authNum && jsonData.authNum.startsWith("ERR")) ) {
               const bitacora = {
                    payPinPad: {
                         status: false,
                         data: jsonData,
                         timestamp: new Date().toISOString(),
                         dni: globalContext.dni,
                    }
               };
               await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
               setShowWaiting(false);
               setShowErrorPaymentMessage(true);
          } else {
               const bitacora = {
                    payPinPad: {
                         status: true,
                         data: jsonData,
                         timestamp: new Date().toISOString(),
                         dni: globalContext.dni,
                    }
               };
               await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
               console.log('payNowJsonData', jsonData);
               setPaymentResponsePinPad(jsonData);
               tryTogetDataFromRNP(jsonData);
          };
     };

     const payWithCode = async (codigo) => {
          try {
               const amount = formatAmount(resultCreateTGR?.result.monto);
               setShowMessageWaitingGetTGR('Validando <strong>el estado del TGR,</strong><br/>por favor espere...');
               setShowWaitingGetTGR(true);          
               const fullName = globalContext.compra?.name + " " + globalContext.compra?.lastName;
               const obj_validar = {
                    tgr: codigo,
                    estado: 'CANJEADO',
                    nombreEnRecibo: fullName,
                    monto: amount,
                    dniSolicitante: globalContext.compra.dniSolicitante
               };
               const tryUpdateAsDelivered = async (lastID, attempt = 1) => {
                    try {
                         await updateAsDelivered(lastID, obj_validar);
                         return true;
                    } catch (error) {
                         console.error('Error al actualizar como entregado', error);
                         if (attempt < 6) {
                              console.log(`Reintentando en 5 segundos... (Intento ${attempt})`);
                              await new Promise(resolve => setTimeout(resolve, 5000));
                              return tryUpdateAsDelivered(lastID, attempt + 1);
                         } else {
                              setBeforeShowAlertError(true);
                              return false;
                         };
                    };
               };
               try {
                    const responseValidate = await validateStatusTGR(codigo);
                    const responseValidateParse = JSON.parse(responseValidate.body);
                    if(responseValidateParse && responseValidateParse.status == true){
                         const bitacora = {
                              RecuperarReciboTGR1: {
                                   status: true,
                                   data: responseValidate,
                                   timestamp: new Date().toISOString(),
                                   dni: globalContext.dni,
                              }
                         };
                         await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
                         let responseVpos = { "authNum": "000000", "Brand": "CODETGR", "Amount": "0000000" + amount, "numReceipt": "0000000", "Reference": "0000000" };
                         let obj = {
                              responseVpos: responseVpos,
                              compra: globalContext.compra,
                         };
                         const datos_bitacora = { 
                              dni : globalContext.dni, 
                              id_bitacora : globalContext.id_bitacora 
                         };
                         const response = await checkoutCode(obj, codigo, datos_bitacora);
                         if (response.response) {
                              globalContext.setResponseVPOS({ ...responseVpos, fullName: fullName, description: 'Acta de defunción' });
                              globalContext.setCertificate(response.response);
                              const updateSuccess = await tryUpdateAsDelivered(response.lastID);
                              if (updateSuccess) {
                                   const bitacora = {
                                        updateAsDelivered: {
                                             status: true,
                                             data: updateSuccess,
                                             timestamp: new Date().toISOString(),
                                             dni: globalContext.dni,
                                        }
                                   };
                                   await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora , true , globalContext.dni);
                                   history.push(process.env.REACT_APP_BASE_URL + "deathPreview");
                              } else {
                                   const bitacora = {
                                        updateAsDelivered: {
                                             status: false,
                                             data: updateSuccess,
                                             timestamp: new Date().toISOString(),
                                             dni: globalContext.dni,
                                        }
                                   };
                                   await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora , true , globalContext.dni);
                                   if (retryCounter >= 6) {
                                        setBeforeShowAlertError(true);
                                        return;
                                   };
                                   setMessageErrorTGR(`Hubo un <strong>error al actualizar el estado del documento. <span style="color: #eee823;">Inténtalo otra vez.</span></strong>`);
                                   setShowWaitingGetTGR(false);
                                   setShowMessageTGR(true);
                              };
                         } else {
                              if (retryCounter >= 6) {
                                   setBeforeShowAlertError(true);
                                   return;
                              };
                              setMessageErrorTGR(`Hubo un <strong>error al procesar el código TGR. <span style="color: #eee823;">Inténtalo otra vez.</span></strong>`);
                              setShowWaitingGetTGR(false);
                              setShowMessageTGR(true);
                         };
                    } else {
                         const bitacora = {
                              RecuperarReciboTGR1: {
                                   status: false,
                                   data: responseValidate,
                                   timestamp: new Date().toISOString(),
                                   dni: globalContext.dni,
                              }
                         };
                         await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
                         setMessageErrorTGR('El código TGR ingresado <strong><span style="color: #eee823;">no está pagado o no existe</span><br/>en nuestros registros.</strong> Por favor, verifica la información<br/>ingresada e intenta nuevamente.');
                         setShowMessageTGR(true);
                         setShowWaitingGetTGR(false);
                    };
               } catch (error) {
                    const bitacora = {
                         RecuperarReciboTGR1: {
                              status: false,
                              data: error,
                              timestamp: new Date().toISOString(),
                              dni: globalContext.dni,
                         }
                    };
                    await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
                    setMessageErrorTGR(`Hubo un <strong>error al procesar el código TGR. <span style="color: #eee823;">Inténtalo otra vez.</span></strong>`);
                    setShowMessageTGR(true);
                    setShowWaitingGetTGR(false);
               };
          } catch (error) {
               const bitacora = {
                    RecuperarReciboTGR1: {
                         status: false,
                         data: error,
                         timestamp: new Date().toISOString(),
                         dni: globalContext.dni,
                    }
               };
               await storeBitacora(JSON.stringify(bitacora), globalContext.id_bitacora, true, globalContext.dni);
               console.log('error', error);
               setMessageErrorTGR(`Hubo un <strong>error al procesar el código TGR. <span style="color: #eee823;">Inténtalo otra vez.</span></strong>`);
               setShowMessageTGR(true);
               setShowWaitingGetTGR(false);
          };
     };
     
     const onAccept = async () => {
          console.log('paymentResponsePinPad', paymentResponsePinPad);
          console.log('retryCounter', retryCounter);
          if (paymentResponsePinPad != null) {
               setShowErrorMessage(false);
               setShowWaiting(true);
               tryTogetDataFromRNP(paymentResponsePinPad, true);
               setRetryCounter(retryCounter + 1);
          };
     };

     const onAcceptFailPayment = () => {
          setShowErrorPaymentMessage(false);
     };

     const onAcceptFailAmount = () => {
          setShowErrorAmountMessage(false);
          history.goBack();
          return;
     };

     const onAcceptTRG = () => {
          setShowMessageTGR(false);
     };

     const funMessageError = () => {
          history.push(process.env.REACT_APP_BASE_URL + "alertError");
          return false;
     };

     return (
          <>
               <GeneralPay
                    onClickFun={payNow}
                    onClickpayWithCode={payWithCode}
                    purchase={globalContext.compra}
                    icon={certdefuncion}
                    detail="l Certificado de Defunción"
                    nameligth="Certificado de"
                    namebold=""
                    nameorange="Defunción"
                    description="El <strong>Certificado de Defunción</strong> pertenece a:"
                    amount={resultCreateTGR?.result.monto ?? "0"}
               ></GeneralPay>
               <div className="center-screen">
                    <ErrorMessageAuto show={showErrorMessage} message={receivedMessage} onAccept={onAccept} labelButton="Reintentar" imageicon={icoF}></ErrorMessageAuto>
                    <ErrorMessageAuto show={showErrorMessageUpdate} message={receivedMessageUpdate} onAccept={setDocumentAsDelivered} labelButton="Reintentar" imageicon={icoF}></ErrorMessageAuto>
                    <ErrorMessage show={showErrorPaymentMessage} message={`El pago <strong>no pudo ser procesado. <span style="color: #eee823;">Inténtalo otra vez.</span></strong>`} onAccept={onAcceptFailPayment} imageicon={icoF}></ErrorMessage>
                    <ErrorMessage show={showErrorAmountMessage} message={`Hubo un <strong>error al obtener el monto del documento. <span style="color: #eee823;">Inténtalo otra vez.</span></strong>`} onAccept={onAcceptFailAmount} imageicon={icoF}></ErrorMessage>
                    <ErrorMessage show={showMessageTGR} message={messageErrorTGR} onAccept={onAcceptTRG} imageicon={icoF}></ErrorMessage>
                    <ErrorMessage show={beforeShowAlertError} message={beforeMessageAlertError} onAccept={funMessageError} imageicon={icoF}></ErrorMessage>
                    <Process show={showWaiting} imageanimation={icon} message={proccessPayMsg}></Process>
                    <Wainting show={showWaitingGetTGR} imageanimation={iconGetTGR} message={showMessageWaitingGetTGR}></Wainting>
               </div>
          </>
     );
};

export default PayDeath;
