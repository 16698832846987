import React from 'react';
import GlobalTitleCertificate from '../../commons/components/TitulosCertificados/GlobalTitleCertificateVertical/GlobalTitleCertificateVertical';
import ImageNacimiento from '../../assets/images/certdefuncion.png';
import './PreviewDeath.css';
import DocumentsOptions from './components/DocumentsOptions/PreviewDeathOptions';
import LogotipoRnp from '../../commons/components/Logotipo/LogotipoRnp/LogotipoRnp';

const PreviewDeath = () => {  

    return (
        <div className='contenedortitle'>
            <div>
                <div>
                    <LogotipoRnp></LogotipoRnp>
                    <GlobalTitleCertificate imgcertificado={ImageNacimiento} nameligth="Certificado de" namebold="" nameorange="Defuncion" ></GlobalTitleCertificate>
                </div>
                <DocumentsOptions></DocumentsOptions>
            </div>
        </div>
    );
};

export default PreviewDeath;
