import React, { useState, useContext, useEffect } from "react";
import "./Invoice.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import AppContext from "../AppContext/AppContext";
import logoRnp from "../../../assets/images/rnplogotipo.png";

const Invoice = ({ _setInvoice }) => {
     const globalContext = useContext(AppContext);

     useEffect(() => {
          generatePDF();
     }, []);

     const generatePDF = () => {
          const pdf = new jsPDF("p", "mm", "letter");
          const content = document.getElementById("pdf-content");
          html2canvas(content).then((canvas) => {
               const imgData = canvas.toDataURL("image/png");
               pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
               pdf.setFontSize(8);
               pdf.setTextColor(150);
               const pdfOutput = pdf.output("datauristring");
               _setInvoice(pdfOutput.replace("data:application/pdf;filename=generated.pdf;base64,",""));
          });
     };

     const getCurrentFormattedDate = () => {
          const currentDate = new Date();
          const day = String(currentDate.getDate()).padStart(2, "0");
          const month = String(currentDate.getMonth() + 1).padStart(2, "0");
          const year = currentDate.getFullYear();
          const hours = String(currentDate.getHours()).padStart(2, "0");
          const minutes = String(currentDate.getMinutes()).padStart(2, "0");
          return `${day}/${month}/${year} ${hours}:${minutes}`;
     };

     const transformAmount = (str) =>{
          const trimmedStr = str.replace(/^0+/, '').slice(0, -2);
          const amount = parseInt(trimmedStr);
          return amount;
     }
      
     return (
          <>
               <div id="pdf-content" className="invoiceRnp_container">
                    <div className="headerfacturarnp">
                         <img src={logoRnp} alt="RNP Logo" className="factrnplogotipo" />
                         <div className="content">
                              <h2>
                                   Factura | <strong>Punto RNP</strong>
                              </h2>
                              <div className="datosrpndesc">
                                   <div className="wrapcontent">
                                        <p>
                                             <strong>Nombre:</strong>
                                             <span>{globalContext.responseVPOS.fullName}</span>
                                        </p>
                                        <p>
                                             <strong>Fecha:</strong>
                                             <span>{getCurrentFormattedDate()}</span>
                                        </p>
                                        <p></p>
                                   </div>
                                   <div className="wrapcontent backgris">
                                        <p>
                                             <strong>Número de factura:</strong>
                                             <span>{globalContext.responseVPOS?.numReceipt}</span>
                                        </p>
                                        <p>
                                             <strong>Número autorización:</strong>
                                             <span>{globalContext.responseVPOS?.authNum}</span>
                                        </p>
                                        <p>
                                             <strong>Número de referencia:</strong>
                                             <span>{globalContext.responseVPOS?.Reference}</span>
                                        </p>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div className="datosfacturarnp">
                         <div className="wrapcontent head">
                              <p className="cantfact">Cant.</p>
                              <p className="cantdesc">Descripción</p>
                              <p className="cantprecio">Precio</p>
                         </div>
                         <div className="wrapcontent">
                              <p className="cantfact">1</p>
                              <p className="cantdesc">{globalContext.responseVPOS.description}</p>
                              <p className="cantprecio">{transformAmount(globalContext.responseVPOS.Amount)}L</p>
                         </div>
                    </div>
                    <div className="datosfooternp">
                         <div className="wrapcontent">
                              <p className="">Total</p>
                              <p className="preciofinal">{transformAmount(globalContext.responseVPOS.Amount)}L</p>
                         </div>
                         <p className="thanksp">Gracias por su compra.</p>
                    </div>
               </div>
          </>
     );
};

export default Invoice;
