import React from 'react';
import './StartButton.css';
import { useHistory } from "react-router-dom";
import BasicButton from '../../../../commons/components/Buttons/BasicButton/BasicButton';

const StartButton = ({ _className,text, size }) => {
    const history = useHistory();

    const handleClick = () => {
        console.log('goto -> Step1');
        history.push(process.env.REACT_APP_BASE_URL + 'authentication');
    };

    return (
        <>
            <BasicButton  _onClick={handleClick} _className={_className} text={text} size={size}></BasicButton>
        </>
    );
};

export default StartButton;