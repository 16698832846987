import React, { useState, useEffect, useContext } from "react";
import "./CertificateMarriageOptions.css";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import AppContext from "../../../../commons/components/AppContext/AppContext";

const CertificateMarriageOptions = ({ setShowErrorMessage, setShowWaiting }) => {
     const [data, setData] = useState([]);
     const history = useHistory();
     const globalContext = useContext(AppContext);

     useEffect(() => {
          setData(globalContext.matrimonios);
     }, [globalContext.matrimonios]);

     const handleClick = async (item) => {
          const namePareja = globalContext.person?.Nombres === item.NombresMujer ? `${item.NombresVaron} ${item.PrimerApellidoVaron} ${item.SegundoApellidoVaron}` : `${item.NombresMujer} ${item.PrimerApellidoMujer} ${item.SegundoApellidoMujer}`;
          const dniPareja = globalContext.person?.Nombres === item.NombresMujer ? (item.NumeroIdentidadVaron.replace(/-/g, '')) : (item.NumeroIdentidadMujer.replace(/-/g, ''));
          const compra={
               typeDocument:"matrimonio",
               certificateId:item.NumInscripcion,
               name:globalContext.person?.Nombres,
               lastName: globalContext.person?.PrimerApellido+" "+globalContext.person?.SegundoApellido,
               dniSolicitante:globalContext.person?.NumInscripcion,
               namepareja: namePareja,
               dnipareja: dniPareja
          };
          globalContext.setCompra(compra);
          history.push(process.env.REACT_APP_BASE_URL + "marriagePay");
     };
     
     return (
          <div className="center-screen">
               <Container>
                    <Row className="align-items-center animated animatedFadeInUp fadeInUp">
                         <Col>
                              <div>
                                   <div className="contenedorscroll">
                                        <ul className="ulcontent">
                                             {data.length > 0 ? (
                                                  data.map((item, index) => (
                                                       <li key={index}>
                                                            <img className="icondescarga" src={require("../../../../assets/images/icondescargar.png")} alt="Descarga" />
                                                            <div className="contentparent">
                                                                 <h2>{`${item.NombresVaron} / ${item.NombresMujer}`}</h2>
                                                                 <p>{`#Inscripcion: ${item.NumInscripcion}`}</p>
                                                            </div>
                                                            <a onClick={() => handleClick(item)}>Comprar</a>
                                                       </li>
                                                  ))
                                             ) : (
                                                  <li></li>
                                             )}
                                        </ul>
                                   </div>
                              </div>
                         </Col>
                    </Row>
               </Container>
          </div>
     );
};

export default CertificateMarriageOptions;