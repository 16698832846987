import React, { useContext } from "react";
import "./BtnContinuarDeath.css";
import { getDeathCertificate } from "../../../../../../Services/services";
import { useHistory } from "react-router-dom";
import AppContext from "../../../../../../commons/components/AppContext/AppContext";
import { storeBitacora } from "../../../../../../Services/services";

const BtnContinuarDeath = ({ setShowWait,setErrorMessage, dni }) => {
     const globalContext = useContext(AppContext);
     const history = useHistory();
     const dni_user = globalContext.dni;
     const id_bitacora = globalContext.id_bitacora;

     const handleClick = async () => {
          try {
               console.log("dniDeath", dni);
               if (dni != "" && dni.length === 13) {
                    setShowWait(true);
                    const response = await getDeathCertificate(dni);
                    if (response.status != false) {
                         const bitacora = {
                              lst_Defunciones: {
                                   status: true,
                                   data: response,
                                   timestamp: new Date().toISOString(),
                                   dni: dni_user,
                              }
                         };
                         await storeBitacora(JSON.stringify(bitacora), id_bitacora , true ,dni_user);
                         const compra = {
                              typeDocument: "defuncion",
                              certificateId: response.defunciones[0].NumInscripcion,
                              nameDifunto:response.defunciones[0].Nombres+" "+response.defunciones[0].PrimerApellido+" "+response.defunciones[0].SegundoApellido,
                              name: globalContext.person?.Nombres,
                              lastName: globalContext.person?.PrimerApellido + " " + globalContext.person?.SegundoApellido,
                              dniSolicitante: globalContext.person?.NumInscripcion,
                              dniDifunto: dni
                         };
                         console.log("Death compra", compra);
                         globalContext.setCompra(compra);
                         history.push(process.env.REACT_APP_BASE_URL + "payDeath");
                    } else {
                         const bitacora = {
                              lst_Defunciones: {
                                   status: false,
                                   data: response,
                                   timestamp: new Date().toISOString(),
                                   dni: dni_user,
                              }
                         };
                         await storeBitacora(JSON.stringify(bitacora), id_bitacora , true ,dni_user);
                         setShowWait(false);
                         setErrorMessage(true);
                    };
                    console.log("response death", response);
               };
          } catch (error) {
               const errorMessage = error.response?.data?.message || error.message || 'Error desconocido';
               const bitacora = {
                    lst_Defunciones: {
                         status: false,
                         data: errorMessage,
                         timestamp: new Date().toISOString(),
                         dni: dni_user,
                    }
               };
               await storeBitacora(JSON.stringify(bitacora), id_bitacora , true ,dni_user);
               setErrorMessage(true);
               setShowWait(false);
          };
     };
     
     return (
          <>
               <div className="contenedorcertificado4 animated animatedFadeInUp fadeInUp" style={{ marginTop: '10px' }} onClick={handleClick}>
                    <a style={{ height: '100px' }}>
                         <p className="textbtncert" style={{ marginTop: '-2px', fontSize: '40px' }}>
                              <img className="icondescarga" src={require("../../../../../../assets/images/iconsearch.png")} alt="Search" style={{ width: '100px' }}/> Consultar
                         </p>
                    </a>
               </div>
          </>
     );
};

export default BtnContinuarDeath;
