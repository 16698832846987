import React, { useState } from 'react';
import AppContext from './commons/components/AppContext/AppContext';
import Home from './pages/Home/Home';
import { clog} from './Services/services';
import './App.css';

export const App = (props) => {
  //****************************************************************** */
  const [person, setPerson] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [hijos, setHijos] = useState([]);
  const [matrimonios, setMatrimonios] = useState([]);
  const [compra, setCompra] = useState(null);
  const [responseVPOS, setResponseVPOS] = useState(null);
  const [facial, _setFacial] = useState(null);
  const [finger, _setFinger] = useState(null);
  const [showFacialCapture, setShowFacialCapture] = useState(false);
  const [showFingerCaputre, setShowFingerCaputre] = useState(false);
  const [validateDNI, setValidateDNI] = useState(false);
  const [id_bitacora, setIdBitacora] = useState(0);
  const [id_customer, setIdCustomer] = useState(0);
  const [dni , setDni] = useState("")
  const [failedFingerprintAttempts, setFailedFingerprintAttempts] = useState(0);
  const [ayuda, setayuda] = useState(true);
  const [codigoEntrega , setcodigoEntrega] = useState("")
  const [nacimiento, setnacimiento] = useState(false);

  const incrementFailedFingerprintAttempts = () => {
      setFailedFingerprintAttempts(prev => prev + 1);
  };

  const handleClickModalFacial = () => {
    setShowFacialCapture(true);
  };

  const hideFacialCapture = () => {
    setShowFacialCapture(false);
  };

  const handleClickFinger = () => {
    setShowFingerCaputre(true);
    test.startCapture();
  };

  const hideFingerCaputre = () => {
    setShowFingerCaputre(false);
  };

 const globalSettings = {
    clog,
    person: person,
    certificate,
    matrimonios,
    compra,
    responseVPOS,
    setResponseVPOS,
    setPerson: setPerson,
    setCertificate,
    hijos,
    setHijos,
    setMatrimonios,
    setCompra,
    facial,
    _setFacial,
    ayuda,
    setayuda,
    finger,
    _setFinger,
    handleClickModalFacial,
    incrementFailedFingerprintAttempts,
    failedFingerprintAttempts, 
    setFailedFingerprintAttempts,
    hideFacialCapture,
    showFacialCapture,
    handleClickFinger,
    hideFingerCaputre,
    showFingerCaputre,
    validateDNI,
    setValidateDNI,
    setIdBitacora,
    id_bitacora,
    setDni,
    dni,
    setIdCustomer,
    id_customer,
    codigoEntrega,
    setcodigoEntrega,
    nacimiento,
    setnacimiento
  };

  return (
    <AppContext.Provider value={globalSettings}>
      <div>   
        <Home />
      </div>
    </AppContext.Provider>
  );
};
export default App;