import React, { useEffect, useContext,useState } from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import './InputDeath.css';
import AppContext from '../../../../../../commons/components/AppContext/AppContext';

const InputDeath = ({_setDni}) => {    
    const globalContext = useContext(AppContext);
    const [inputValue,setInputValue]=useState('');
    const [isKeyboard, setIsKeyboard] = useState(false);
    const [firstTimeKeyBoard, setFirstTimeKeyBoard] = useState(true);
    const [showErrorMessageDNI, setShowErrorMessageDNI] = useState(false);
    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
    const [Aux, setAux] = useState('');
    const [AuxState, setAuxState] = useState(false);

    useEffect(() => {
        _setDni(inputValue.replace(/\s/g, ''));
    }, [inputValue]);

    useEffect(() => {
    }, [isKeyboardVisible]);

    const excludeFromLayout= {
        default: ["`","@","'",";","=","-",".",",","\\","{tab}","{lock}","{shift}","/","]","[", ".com"]
    };

    const enableKeyBoard = () => {
        setFirstTimeKeyBoard(false);
        setIsKeyboardVisible(true);
    };

    const disableKeyBoard = () => {
        setIsKeyboardVisible(false);
    };

    const formatDNI = (value) => {
        value = value.replace(/\D/g, '');
        if (value.length > 4) {
            value = value.slice(0, 4) + ' ' + value.slice(4);
        };
        if (value.length > 9) {
            value = value.slice(0, 9) + ' ' + value.slice(9);
        };
        return value;
    };

    const onKeyPressKeyBoard = (button ) => {
        console.log("Button pressed", button);
        if (button === "{bksp}") {
            setInputValue(prev => formatDNI(prev.slice(0, -1)));
            globalContext.setValidateDNI(false);
        } else if (button === "{enter}") {
            disableKeyBoard();
            if (!globalContext.validateDNI) {
                setShowErrorMessageDNI(true);
            };
        } else {
            if (inputValue.length <= 14) {
                const formattedInput = formatDNI(inputValue + button);
                setInputValue(formattedInput);
                globalContext.setValidateDNI(false);
                console.log("Input changed", formattedInput);
                if (inputValue.length === 14) {
                    globalContext.setValidateDNI(true);
                    setShowErrorMessageDNI(false);
                    console.log("final", formattedInput);
                    globalContext.handleClickFinger();
                    disableKeyBoard();
                };
            } else {
                if (inputValue.length === 15) {
                    globalContext.setValidateDNI(true);
                    setShowErrorMessageDNI(false);
                    console.log("final", inputValue);
                    globalContext.handleClickFinger();
                    disableKeyBoard();
                };
            };
        };
    };

    return (
        <>
            <div className='animated animatedFadeInUp fadeInUp'>
                <div className='messageSelect'>
                    <p style={{ marginBottom: '-20px' }}>Ingresa el <strong>DNI del Difunto</strong></p>
                </div>    
                <input value={inputValue} className='inputDNI' type="text" maxLength={15} pattern="[A-Za-z0-9]*" onFocus={enableKeyBoard} onChange={(e) => setInputValue(e.target.value)} placeholder='DNI' readOnly={true} />
            </div>
            {showErrorMessageDNI && (
                <div>
                    <span style={{ color: 'red' }}>El Documento Nacional de Identificación debe tener 13 dígitos</span>
                </div>
            )}
            {isKeyboardVisible && (
                <div
                    className="keyboard-container"
                    style={{
                        animation: `${!firstTimeKeyBoard?(isKeyboard ? "slideIn" : "slideOut") : ""} 0.5s forwards`,
                        width: '350px'
                    }}
                >
                    <Keyboard
                        excludeFromLayout={excludeFromLayout}
                        theme={"hg-theme-default myTheme1"}
                        layoutName={"caps"}
                        onKeyPress={onKeyPressKeyBoard}
                        layout={{
                            caps: [
                                "1 2 3",
                                "4 5 6",
                                "7 8 9",
                                "{bksp} 0 {enter}"
                            ]
                        }}
                        display={{
                            '{bksp}': '<',
                            '{enter}': 'Salir'
                        }}
                        buttonTheme={[
                            {
                                class: "hg-enter",
                                buttons: "{enter}"
                            },
                            {
                                class: "hg-button-bksp",
                                buttons: "{bksp}"
                            }
                        ]}
                    />
                </div>
            )}           
        </>
    );
};

export default InputDeath;