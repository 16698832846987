import React, { useState } from "react";
import { Modal, Button  } from "react-bootstrap";
import "./GeneralPayOptionBtnTGR.css";
import Keyboard from "react-simple-keyboard";

const GeneralPayOptionBtnTGR = ({ onClickpayWithCode }) => {
  const [showModal, setShowModal] = useState(false);
  const [tgr, setTGR] = useState("");
  const [isKeyboard, setIsKeyboard] = useState(false);
  const [isValidTGR, setIsValidTGR] = useState(false);

  const openModal = () => {
    setTGR('');
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setTGR('');
    setIsValidTGR(false);
    setIsKeyboard(false);
  };

  const enableKeyBoard = () => {
    setIsKeyboard(true);
  };

  const disableKeyBoard = () => {
    setIsKeyboard(false);
  };

  const handlePay = () => {
    if (isValidTGR) {
      setShowModal(false);
      onClickpayWithCode(tgr);
    };
  };

  const excludeFromLayout = {
    default: ["`", "@", "'", ";", "=", "-", ".", ",", "\\", "{tab}", "{lock}", "{shift}", "/", "]", "[", ".com"],
  };

  const display = {
    "{bksp}": "<",
    "{enter}": "↵",
    "{space}": "ESPACIO",
  };

  const onKeyPressKeyBoard = (button ) => {
    console.log("Button pressed", button);
    if (button === "{bksp}") {
      setTGR(prev => prev.slice(0, -1));
    } else if (button === "{enter}") {
      disableKeyBoard();
    } else {
      if (tgr.length <= 7) {
        const formattedInput = tgr + button;
        setTGR(formattedInput);
        console.log("Input changed", formattedInput);
        if (tgr.length === 7) {
          setIsValidTGR(true);
          console.log("final", formattedInput);
          disableKeyBoard();
        };
      } else {
        if (tgr.length === 8) {
          setIsValidTGR(true);
          console.log("final", tgr);
          disableKeyBoard();
        };
      };
    };
  };

  return (
    <>
      <div className="contenedorcertificado3 animated animatedFadeInUp fadeInUp hover-border">
        <a onClick={openModal}>
          <p><img className="icondescarga" src={require("../../../../../../../assets/images/insertcode.png")} alt="Impresion" />Usar TGR</p>
        </a>
      </div>
      <Modal show={showModal} onHide={closeModal} className="custom-modal1" backdropClassName="custom-modal1-backdrop" centered backdrop="static">
        <Modal.Header className="custom-modal1-header">
          <Modal.Title>Validar TGR</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5 className='span-contacto'><strong>El número de tu TGR</strong> aparece en el<br/>recibo que te proporcionó el banco.</h5>
            <input value={tgr} className="phone-input" type="text" maxLength={8} pattern="[A-Za-z0-9]*" onFocus={enableKeyBoard} onChange={(e) => setTGR(e.target.value)} placeholder="DIGITA TU TGR" readOnly={true} />
          </div>
          {isKeyboard && (
            <div>
              <Keyboard
                excludeFromLayout={excludeFromLayout}
                theme={"hg-theme-default myTheme1"}
                layoutName={"caps"}
                onKeyPress={onKeyPressKeyBoard}
                display={display}
                layout={{
                  caps: [
                    "1 2 3",
                    "4 5 6",
                    "7 8 9",
                    "{bksp} 0 {enter}"
                  ]
                }}
                buttonTheme={[
                  {
                    class: "hg-enter",
                    buttons: "{enter}"
                  },
                  {
                    class: "hg-button-bksp",
                    buttons: "{bksp}"
                  }
                ]}
              />
            </div>
          )}
          <Button variant="success" onClick={handlePay} disabled={!isValidTGR}>
            Enviar
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GeneralPayOptionBtnTGR;