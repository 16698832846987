import React from 'react';
import GlobalTitleCertificate from '../../commons/components/TitulosCertificados/GlobalTitleCertificateVertical/GlobalTitleCertificateVertical';
import ImageNacimiento from '../../assets/images/certdefuncion.png';
import './SearchDeath.css';
import DocumentsOptions from './components/DocumentsOptions/SearchDeathOptions';
import LogotipoRnp from '../../commons/components/Logotipo/LogotipoRnp/LogotipoRnp';
import NameUser from "../../commons/components/UserNames/NameUser/NameUser";
import { Container, Row, Col } from 'react-bootstrap';
import IrMenu from "../../commons/components/Buttons/IrMenu/IrMenu";
import Close from "../../commons/components/Buttons/Close/Close";

const SearchDeath = () => {  

    return (        
        <div>
            <div className="contenedortitle">
                <LogotipoRnp></LogotipoRnp>
                <NameUser></NameUser>            
                <Container>
                    <Row className="align-items-center">
                        <Col>
                            <GlobalTitleCertificate imgcertificado={ImageNacimiento} nameligth="Certificado de" namebold="" nameorange="Defunción" ></GlobalTitleCertificate>
                        </Col>
                        <Col>
                            <DocumentsOptions>  </DocumentsOptions>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col>
                            <div className="alignbtnfooter">
                                <div className="center">                                
                                    <IrMenu></IrMenu>
                                    <Close></Close>
                                </div>
                            </div>
                        </Col>                 
                    </Row>  
                </Container>
            </div>
        </div>
    );
};

export default SearchDeath;