import React, {useEffect} from 'react';
import './ErrorMessage.css';

const ErrorMessageAuto = ({ show, message, onAccept, imageicon,labelButton='ACEPTAR', imgWaring = '' }) => {

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        handleAcceptClick()
      }, 5000);
      return () => clearTimeout(timer);
    };
  }, [show]);

  const handleAcceptClick = () => {
    onAccept();
  };

  if (!show) {
    return null;
  };

  return (
    <div className='error-overlay2'>
      <div className='error-content'>
      <img src={imageicon} alt="Alert"/>
        <div className='error-message' dangerouslySetInnerHTML={{ __html: message }}/>
        {
          imgWaring !== '' && (
            <img src={imgWaring} alt="Alert"/>
          )
        }
      </div>
    </div>
  );
};

export default ErrorMessageAuto;
