import React, { useState } from 'react';
import "./IdentificationData.css";
import { Container, Row, Col } from "react-bootstrap";
import FingerprintButton from "./components/FingerprintButton/FingerprintButton";
import FacialRecognitionButton from "./components/FacialRecognitionButton/FacialRecognitionButton";
import DniInput from "./components/DniInput/DniInput";

const IdentificationData = ({ _setDni, _onClick }) => {
    const [dniLength, setDniLength] = useState(0);
    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

    const handleDniChange = (dni) => {
        _setDni(dni);
        setDniLength(dni.length);
    };

    const handleKeyboardVisibilityChange = (isVisible) => {
        setIsKeyboardVisible(isVisible);
    };

    return (
        <>
            <div>
                <Container>
                    <Row>
                        <Col>
                            <p className="pasosclass labelTitles animated animatedFadeInUp fadeInUp">Ingresa el número de tu Documento Nacional de Identificación.</p>
                            <DniInput _setDni={handleDniChange} _setIsKeyboardVisible={handleKeyboardVisibilityChange} />
                        </Col>
                    </Row>
                    {dniLength === 13 && !isKeyboardVisible && (
                        <>
                            <Row>
                                <Col>
                                    <p className="pasosclass labelTitles animated animatedFadeInUp fadeInUp">Realiza tu identificación biométrica.</p>
                                </Col>
                            </Row>
                            <Row className='IdentContainer'>
                                <div>
                                    <FingerprintButton _onClick={_onClick} />
                                </div>
                                <div>
                                    <FacialRecognitionButton _onClick={_onClick} />
                                </div>
                            </Row>
                        </>
                    )}
                    <Row>
                        <p className="pasosclass labelTitles mensajeimportante animated animatedFadeInUp fadeInUp"><strong>Aviso:</strong> Asegúrate de digitar correctamente tu DNI y de haber completado el proceso de auténticación biométrica.</p>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default IdentificationData;