import React from 'react';
import './WarningMessage.css';

const WarningMessage = ({ overlayblue, show, message, onAccept, imageicon, labelButton = 'ACEPTAR', imgWaring = '' }) => {

  if (!show) {
    return null;
  };

  return (
    <div className={overlayblue ? 'error-overlay1' : 'error-overlay2'}>
      <div className='error-content'>
        <img src={imageicon} alt="Alert" />
        <div className='error-message' dangerouslySetInnerHTML={{ __html: message }}/>
        {imgWaring !== '' && (
          <img src={imgWaring} alt="Alert" />
        )}
        <div>
          <button className="error-button" onClick={onAccept}>{labelButton}</button>
        </div>
      </div>
    </div>
  );
};

export default WarningMessage;
