import React, { useState } from 'react';
import './DocumentSelector.css';
import DocumentsOptions from './components/DocumentsOptions/DocumentsOptions';
import Wainting from '../../commons/components/Waiting/Waiting';
import ErrorMessage from '../../commons/components/ErrorMessage/ErrorMessage';

const DocumentSelector = () => {
    const [showWaiting, setShowWaiting] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const onAccept= ()=>{
        setShowErrorMessage(false);
    };

    return (
        <div>
            <div className='contenedorkiosko'>                
                <DocumentsOptions setShowErrorMessage={setShowErrorMessage} setShowWaiting={setShowWaiting}>  </DocumentsOptions>
                <Wainting show={showWaiting}></Wainting>
                <ErrorMessage  show={showErrorMessage} message="No se pudo obtener el certificado. Por favor, inténtelo de nuevo." onAccept={onAccept} ></ErrorMessage>            
            </div>
        </div>
    );
};

export default DocumentSelector;
